import React, { Fragment } from "react";
import _ from "lodash";
import { Input, UncontrolledTooltip } from "reactstrap";

// TO DO
// 1. - Get list of sharepoint used things from Dave

const searchItems = (items, searchString) => {
  let foundItems = [];

  items.forEach((item) => {
    let match = false;

    // Check the name field
    if (item.name && item.name.toLowerCase().includes(searchString.toLowerCase())) {
      match = true;
    }

    // Check the description field only if it exists
    if (item.description && item.description.toLowerCase().includes(searchString.toLowerCase())) {
      match = true;
    }

    let subitemMatches = [];

    // Recursively search in subitems
    if (item.subitems) {
      subitemMatches = searchItems(item.subitems, searchString);
      if (subitemMatches.length > 0) {
        match = true;
        item.subitems = subitemMatches;
      }
    }

    if (match) {
      foundItems.push(item);
    }
  });

  return foundItems;
};

const Portal = (props) => {
  const [searchString, setSearchString] = React.useState("");

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const listItems = [
    {
      name: "Lion V2",
      link: "http://lion.mjh.co.nz",
      subitems: [
        {
          name: "General Use",
          subitems: [
            { name: "Master Project List", link: "https://lion.mjh.co.nz/project" },
            { name: "Project Reports", link: "https://lion.mjh.co.nz/project/reports/index" },
            { name: "Address Book (superseded by V3)", superseded: true, link: "https://lion.mjh.co.nz/administration/address_book" },
            { name: "Master Time Keeping", link: "https://lion.mjh.co.nz/administration/time_keeping" },
            { name: "Check Timesheets", link: "https://lion.mjh.co.nz/administration/time_keepings/check" },
            { name: "Clock Ins and Outs", link: "https://lion.mjh.co.nz/administration/clock_ins" },
            { name: "Lock Days for timesheets", link: "https://lion.mjh.co.nz/administration/locked_days" },
            { name: "NDT Bookings", link: "https://lion.mjh.co.nz/project/qa/ndt_bookings" },
            { name: "Welding Qualifications", link: "https://lion.mjh.co.nz/welding_qualifications" },
            { name: "Store Stock (via LionV2)", link: "https://lion.mjh.co.nz/store/stock" },
            { name: "Store Bookout Items (via LionV2)", link: "https://lion.mjh.co.nz/store/book_out" },
            { name: "Overalls (Not used due to no barcodes anymore)", link: "https://lion.mjh.co.nz/store/overalls" },
            { name: "Visitors (To our offices)", link: "https://lion.mjh.co.nz/visitors" },
            { name: "Do timesheets and clockins web", link: "https://lion.mjh.co.nz/administration/time_keepings" },
          ],
        },
        {
          name: "Project Specific",
          subitems: [
            { name: "Paint Systems (superseded by V3)", superseded: true, link: "https://lion.mjh.co.nz/project/5144/technical_details/index" },
            { name: "Paint QA (superseeded by V3)", link: "https://lion.mjh.co.nz/project/5144/qa/paint_inspections_all" },
            { name: "Drawing Register (for approvals)", link: "https://lion.mjh.co.nz/project/5144/documentation/drawing_register" },
            {
              name: "Communications (RFIs etc, being phased out to V3)",
              link: "https://lion.mjh.co.nz/project/5144/documentation/communications/dashboard",
            },
            { name: "Problem Notices (superseded by V3)", superseded: true, link: "https://lion.mjh.co.nz/project/5144/qa/problem_notices" },
            { name: "Quotes", link: "https://lion.mjh.co.nz/project/5144/quotes" },
            { name: "Variation Register", link: "https://lion.mjh.co.nz/project/5144/variation_numbers" },
            { name: "Percentage Trackers", link: "https://lion.mjh.co.nz/project/1311/qa/percentage_trackers" },
            { name: "Workloads (Not used)", link: "https://lion.mjh.co.nz/workloads" },
            { name: "Welding Visual Inspections", link: "https://lion.mjh.co.nz/project/1311/qa/welding_visual_inspections" },
          ],
        },
        {
          name: "Maintenance Module",
          link: "https://lion.mjh.co.nz/maintenance",
          subitems: [
            { name: "Find Items by operator", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "All Certs Due", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "All Certs Coming up", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "All Services due", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "All Services coming up", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "Incomplete Issues", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "Resend big email", link: "https://lion.mjh.co.nz/maintenance/all_items/dashboard" },
            { name: "Settings - Categories", link: "https://lion.mjh.co.nz/maintenance/settings?tab=Categories" },
            { name: "Settings - Dashboards", link: "https://lion.mjh.co.nz/maintenance/settings?tab=Dashboards" },
            { name: "Settings - Issue Status", link: "https://lion.mjh.co.nz/maintenance/settings?tab=IssueStatuses" },
            { name: "Settings - Certificates", link: "https://lion.mjh.co.nz/maintenance/settings?tab=Certificates" },
          ],
        },
        {
          name: "HR Module",
          link: "https://lion.mjh.co.nz/administration/hr",
          subitems: [
            { name: "Dashboard", link: "https://lion.mjh.co.nz/administration/hr" },
            { name: "Employees List", link: "https://lion.mjh.co.nz/administration/hr/employees" },
            { name: "Tool Accounts", link: "https://lion.mjh.co.nz/administration/hr/tool_accounts" },
            { name: "Advancements", link: "https://lion.mjh.co.nz/administration/hr/advancements" },
            { name: "General HR Information/Files", link: "https://lion.mjh.co.nz/administration/hr/information_groups" },
            { name: "Scheduled reports", link: "https://lion.mjh.co.nz/administration/hr/scheduled_reports" },
            { name: "HR Settings", link: "https://lion.mjh.co.nz/administration/hr/scheduled_reports" },
          ],
        },
      ],
    },
    {
      name: "Sharepoint",
      link: "https://mjhengineering.sharepoint.com/",
      subitems: [
        { name: "Technical Review/s", link: "https://mjhengineering.sharepoint.com/quality/Technical%20Review/Forms/AllItems.aspx" },
        {
          name: "Load List (for transport)",
          link: "https://mjhengineering.sharepoint.com/production/Lists/LoadList/EDIT%20JobNoLoadNo.aspx#InplviewHash69304a80-5026-4f06-9076-6ee49062867e=ShowInGrid%3DTrue",
        },
      ],
    },
    {
      name: "Lion V3",
      link: "https://lion3.mjh.co.nz",
      subitems: [
        { name: "Address Book - Companies", link: "https://lion3.mjh.co.nz/address_book?tab=companies" },
        { name: "Address Book - Contacts", link: "https://lion3.mjh.co.nz/address_book?tab=contacts" },
        { name: "Project List V3 (not master)", link: "https://lion3.mjh.co.nz/projects" },
        { name: "Employees List V3 (not master)", link: "https://lion3.mjh.co.nz/employees" },
        { name: "Communications Dashboard (phasing in from V2)", link: "https://lion3.mjh.co.nz/project/27/communications" },
        { name: "Transport Loading Checksheets", link: "https://lion3.mjh.co.nz/custom_form/18/records/" },
        {
          name: "Contractors Documents etc",
          link: "https://lion3.mjh.co.nz/custom_form/18/records/",
          description: "Contractors documents, insurances, etc",
        },
        { name: "Crawler Crane checksheets", description: "CC", link: "https://lion3.mjh.co.nz/custom_form/8/records/" },

        {
          name: "Project Specific",
          subitems: [
            {
              name: "Variation Numbers (not master)",
              link: "https://lion3.mjh.co.nz/projects/52/show?tab=variation_numbers",
            },
            {
              name: "Areas",
              link: "https://lion3.mjh.co.nz/projects/52/show?tab=areas",
            },
            {
              name: "Site Checklist Items",
              link: "https://lion3.mjh.co.nz/projects/52/show?tab=site_checklist_items",
            },
            {
              name: "Site Document Releases",
              description: "This is primarly used to release  GA drawings to site",
              link: "https://lion3.mjh.co.nz/project/27/site_document_releases",
            },
            {
              name: "Paint Systems (Master)",
              description: "Paint systems for the project with details of dft levels etc",
              link: "https://lion3.mjh.co.nz/projects/52/show?tab=technical_details",
            },
            {
              name: "GA Drawings (for site)",
              link: "https://lion3.mjh.co.nz/project/27/drawings",
            },
            {
              name: "Part / Assembly Drawings from strumis via LionV3",
              link: "https://lion3.mjh.co.nz/project/27/drawings",
            },
          ],
        },
        {
          name: "Production",
          subitems: [
            { name: "Job Board/s (Select job)", link: "https://lion3.mjh.co.nz/production/job_boards/select" },
            { name: "Overall Jobs Schedule", link: "https://lion3.mjh.co.nz/production/schedule" },
            { name: "Transport Boards", link: "https://lion3.mjh.co.nz/production/job_boards/select" },
            { name: "Paint Board", link: "https://lion3.mjh.co.nz/production/paint_boards/show" },
          ],
        },
        {
          name: "QA Module",
          subitems: [
            { name: "QA Model View", link: "https://lion3.mjh.co.nz/qa" },
            { name: "Paint Inspections (Master)", link: "https://lion3.mjh.co.nz/project/27/qa/paint_inspections" },
            { name: "Action Items (replaces Problem notices)", link: "https://lion3.mjh.co.nz/project/qa/action_items" },
            { name: "Site QA", link: "https://lion3.mjh.co.nz/projects/qa/site/dashboard" },
            { name: "Surveillance Reports", link: "https://lion3.mjh.co.nz/project/27/qa/site/surveillances" },
            { name: "Bolted Connections", link: "https://lion3.mjh.co.nz/project/1/qa/bolted_connections" },
          ],
        },
        {
          name: "Custom Forms",
          link: "https://lion3.mjh.co.nz/custom_forms",
        },
      ],
    },
    {
      name: "Docs.mjh.co.nz",
      link: "http://docs.mjh.co.nz/",
      subitems: [
        {
          name: "Drawing Office Wiki",
          link: "http://docs.mjh.co.nz/dwgs/start",
          subitems: [
            {
              name: "How to",
              description: "Various ways the drawing office models and does things",
              link: "http://docs.mjh.co.nz/dwgs/howto/start",
            },
            {
              name: "Drawing Office Manual (MJH)",
              link: "http://docs.mjh.co.nz/dwgs/manual/start",
            },
            {
              name: "Drawing Office Manual (Contractor)",
              link: "http://docs.mjh.co.nz/dwgs/manualcontractor/start",
            },
            {
              name: "Checklists",
              description: "Checklists for various drawing office tasks",
              link: "http://docs.mjh.co.nz/dwgs/checklists/start",
            },
            {
              name: "References",
              description: "Reference documents for the drawing office",
              link: "http://docs.mjh.co.nz/dwgs/reference/start",
            },
          ],
        },
        {
          name: "Administration, Accounts and Payroll",
          link: "http://docs.mjh.co.nz/accts/start",
          description: "Process and procedures for accounts and payroll",
        },
        {
          name: "Strumis Help and Procedures",
          link: "http://docs.mjh.co.nz/strumis/start",
        },
      ],
    },
    {
      name: "Health and Safety",
      subitems: [
        {
          name: "Site App Pro",
          subitems: [
            {
              name: "Dashboard",
              link: "https://my.siteapppro.com/#/dashboard",
            },
            {
              name: "Forms",
              link: "https://my.siteapppro.com/#/form",
            },
            {
              name: "Reports",
              link: "https://my.siteapppro.com/#/report",
            },
          ],
        },
      ],
    },
  ];

  let filteredListItems = _.cloneDeep(listItems);
  if (searchString) {
    filteredListItems = searchItems(filteredListItems, searchString);
  }

  return (
    <Fragment>
      <Input name="search" placeholder="Search..." onChange={handleSearch} />
      <hr />
      <ol>
        <List items={filteredListItems} />
      </ol>
    </Fragment>
  );
};

export default Portal;

const List = (props) => {
  const { items } = props;

  return (
    <Fragment>
      {_.map(items, (item, i) => (
        <ListItem key={i} item={item} />
      ))}
    </Fragment>
  );
};

const ListItem = (props) => {
  const { item } = props;

  return (
    <li>
      {item.link ? (
        <a href={item.link} target="_blank" id={_.snakeCase(item.name)}>
          {item.name}
        </a>
      ) : (
        <span id={_.snakeCase(item.name)}>{item.name}</span>
      )}
      {item.subitems && (
        <ul>
          <List items={item.subitems} />
        </ul>
      )}
      {item.description && (
        <UncontrolledTooltip placement="right" target={_.snakeCase(item.name)}>
          {item.description}
        </UncontrolledTooltip>
      )}
    </li>
  );
};
