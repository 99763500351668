import React, { Fragment } from "react";

import CreatableSelect from "react-select/creatable";

import { Row, Col } from "reactstrap";

export default function CreatableMultiselect(props) {
  const { input, meta, options, placeholder, valueName, instanceId, convertToUpperCase, isMulti, disabled } = props;
  const { name, value } = input;
  const { touched, error, warning } = meta;

  const customOnChange = (values) => {
    const newValues = _.clone(values);
    if (valueName) {
      _.each(newValues, (value) => {
        if (convertToUpperCase) {
          value.value = value.value.toUpperCase();
          value.label = value.value.toUpperCase();
        }
        value[valueName] = value.value.toUpperCase();
      });
    }

    input.onChange(newValues);
  };

  return (
    <Fragment>
      <Row>
        <Col sm={12}>
          <CreatableSelect
            isMulti={isMulti}
            onChange={customOnChange}
            options={options}
            classNamePrefix="react-select"
            instanceId={instanceId}
            valueKey="value"
            value={value}
            name={name}
            placeholder={placeholder}
            isDisabled={disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {touched &&
            ((error && (
              <span className="text-danger">
                {name} {error}
              </span>
            )) ||
              (warning && <span className="text-warning">{warning}</span>))}
        </Col>
      </Row>
    </Fragment>
  );
}

CreatableMultiselect.defaultProps = {
  instanceId: "createable-select",
  isMulti: true,
};
