import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS = "GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS";
export const getProductionStrumisContractDocuments = (project_id) => {
  const url = `/production/strumis/contracts/${project_id}/documents.json`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS,
    payload: request,
  };
};

export const GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENT_GET_BY_GUID = "GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENT_GET_BY_GUID";
export const getProductionStrumisContractDocumentGetByGUID = (guid) => {
  const url = `/production/strumis/contracts/documents/get_by_guid.json?GUID=${guid}`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENT_GET_BY_GUID,
    payload: request,
  };
};
