import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from "reactstrap";
import classnames from "classnames";

import DetailsTab from "./form/DetailsTab";
import CommunicationsTab from "./form/CommunicationsTab";
import TrimbleConnectTab from "./form/TrimbleConnectTab";

export default class ProjectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "details",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    const { handleSubmit, formLabel, history, cancelFunction, employees, production_strumis_contracts } = this.props;

    return (
      <form>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "details" })}
              onClick={() => {
                this.toggle("details");
              }}
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "communications" })}
              onClick={() => {
                this.toggle("communications");
              }}
            >
              Communications
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "trimble_connect" })}
              onClick={() => {
                this.toggle("trimble_connect");
              }}
            >
              Trimble Connect Details
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="details">
            <DetailsTab {...this.props} />
          </TabPane>
          <TabPane tabId="communications">
            <CommunicationsTab {...this.props} />
          </TabPane>
          <TabPane tabId="trimble_connect">
            <TrimbleConnectTab {...this.props} />
          </TabPane>
        </TabContent>

        <hr />
        <Row>
          <Col>
            <Button color="success" onClick={handleSubmit}>
              {formLabel} Project
            </Button>
          </Col>
          <Col className="text-right">
            <Button onClick={cancelFunction} className="float-right" color="danger">
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
