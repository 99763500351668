
import React, { Fragment, useEffect, useState } from 'react';

import { decompressGUID } from 'modules/5_view_helpers/guidConverter';
import { getProductionStrumisContractDocumentGetByGUID } from 'modules/production/strumis/contract/documents/actions';
import { connect } from 'react-redux';
import { DrawingRevisionsTabs } from 'modules/project/drawings/web/modal';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        production_strumis_contract_documents: state.production_strumis_contract_documents
    }
}

const DrawingsTab = (props) => {
    const [GUID, setGUID] = useState(null)
    const { object, production_strumis_contract_documents, modelID } = props
    useEffect(() => {
        tc_api.viewer.convertToObjectIds(modelID, [object.id]).then((guids) => {
            setGUID(decompressGUID(guids[0]))
        })
    }, [])

    useEffect(() => {
        if (GUID) {
            props.getProductionStrumisContractDocumentGetByGUID(GUID)
        }
    }, [GUID])


    return (
        <Fragment>
            {_.isEmpty(production_strumis_contract_documents) && (<div>Loading...</div>)}
            <div style={{ height: "80vh" }} >
                <DrawingRevisionsTabs
                    drawing={production_strumis_contract_documents}
                />
            </div>
        </Fragment >
    )
}

export default connect(mapStateToProps, { getProductionStrumisContractDocumentGetByGUID })(DrawingsTab);

