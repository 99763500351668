import React, { Fragment, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SlidingPane from "react-sliding-pane";
import _ from 'lodash';
import DrawingsTab from './DrawingsTab';
import PaintInspectionsTab from './PaintInspectionsTab';


const TrimbleConnectSlidingPane = (props) => {
    const { toggle, isOpen, selectedObjects, modelID } = props
    const [activeTab, setActiveTab] = useState('details');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <SlidingPane isOpen={isOpen} onRequestClose={toggle} title={`Selected : ${selectedObjects}`} width="60%">
            <Nav tabs>
                {_.map(selectedObjects, (object, i) => {
                    const properties = object?.properties
                    const findTeklaAssembly = _.find(properties, p => p.name == "Tekla Assembly")
                    const findMark = _.find(findTeklaAssembly?.properties, t => t.name == "Assembly/Cast unit Mark")
                    const mark = findMark?.value || "Cant Find Name"

                    return (
                        <NavItem key={i}>
                            <NavLink
                                className={classnames({ active: activeTab === object.id })}
                                onClick={() => { toggleTab(object.id); }}
                            >
                                {mark}
                            </NavLink>
                        </NavItem>
                    )
                })}

            </Nav>
            <TabContent activeTab={activeTab}>
                {_.map(selectedObjects, (object, i) => {
                    return (
                        <TabPane tabId={object.id} key={i}>
                            {activeTab == object.id && <SelectedObjectDetails object={object} modelID={modelID} />}
                        </TabPane>
                    )
                })}
            </TabContent>
        </SlidingPane>
    );
}

export default TrimbleConnectSlidingPane


const SelectedObjectDetails = (props) => {
    const [activeTab, setActiveTab] = useState('details');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const { object, modelID } = props
    const properties = object?.properties

    const findTeklaAssembly = _.find(properties, p => p.name == "Tekla Assembly")
    const findMark = _.find(findTeklaAssembly?.properties, t => t.name == "Assembly/Cast unit Mark")
    const mark = findMark?.value || "Cant Find Name"

    const findDefault = _.find(properties, p => p.name == "Default")
    const findPhase = _.find(findDefault?.properties, t => t.name == "PHASE")
    const findLot = _.find(findDefault?.properties, t => t.name == "LOT_NUMBER")
    const phase = findPhase?.value || "Cant find phase"
    const lot = findLot?.value || "Cant find lot"


    return (
        <Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === 'details' })}
                        onClick={() => { toggleTab('details'); }}
                    >
                        Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === 'drawings' })}
                        onClick={() => { toggleTab('drawings'); }}
                    >
                        Drawings
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === 'paint_inspections' })}
                        onClick={() => { toggleTab('paint_inspections'); }}
                    >
                        Paint Inspections
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="details">
                    <Row>
                        <Col md={2}><b>Mark:</b></Col>
                        <Col md={2}>
                            {mark}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}><b>Phase:</b></Col>
                        <Col md={2}>
                            {phase}
                        </Col>
                        <Col md={2}><b>Lot:</b></Col>
                        <Col md={2}>
                            {lot}
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tabId="drawings">
                    {activeTab == "drawings" && <DrawingsTab modelID={modelID} object={object} />}
                </TabPane>
                <TabPane tabId="paint_inspections">
                    {activeTab == "paint_inspections" && <PaintInspectionsTab modelID={modelID} object={object} />}
                </TabPane>

            </TabContent>
        </Fragment>
    )
}


