import React, { Fragment } from "react";
import { ListInput, ListItem } from "framework7-react";

const DateField = (props) => {
  const {
    input: { value, onChange, onBlur, type },
    meta: { touched, error, warning },
    tabIndex,
    listInput,
    postiveOnly,
    convertToUpperCase,
    ...rest
  } = props;

  return (
    <Fragment>
      <ListInput type="date" onChange={onChange} onBlur={onBlur} value={value} tabindex={tabIndex} {...rest} />
      {touched && error ? <ListItem className="text-danger">{error}</ListItem> : undefined}
    </Fragment>
  );
};

export default DateField;
