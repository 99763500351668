import HasRole from "modules/5_view_helpers/HasRole";

const getDrawingOfficeNav = (project_id, custom_forms_menus) => {
  const SiteDocumentReleaseRoles = HasRole({
    resource_type: "Project::SiteDocumentRelease",
  });
  const ProjectDrawingRole = HasRole({
    resource_type: "Project::Drawing",
  });
  const DrawingTypesRoles = HasRole({
    resource_type: "DrawingOffice::DrawingType",
  });


  const CommunicationsStatusesRoles = HasRole({ resource_type: "Project::Communications::Status", name: ["read", "moderator"] });
  const CommunicationsOutgoingTypesRoles = HasRole({ resource_type: "Project::Communications::OutgoingType", name: ["read", "moderator"] });

  const DrawingOfficeRoles = SiteDocumentReleaseRoles || ProjectDrawingRole || DrawingTypesRoles;

  if (DrawingOfficeRoles) {
    let drawingOfficeMenu = {};
    _.set(drawingOfficeMenu, "icon", "fa fa-pencil");
    _.set(drawingOfficeMenu, "label", "Drawing Office");
    _.set(drawingOfficeMenu, "content", []);
    let content = _.get(drawingOfficeMenu, "content", []);


    if (SiteDocumentReleaseRoles) {
      content.push({
        label: "Site Document Releases",
        to: `/project/${project_id}/site_document_releases`,
      });
    }
    if (ProjectDrawingRole) {
      content.push({
        label: "Drawings",
        to: `/project/${project_id}/drawings`,
      });
    }


    const SettingsRoles = DrawingTypesRoles || CommunicationsStatusesRoles || CommunicationsOutgoingTypesRoles;

    if (SettingsRoles) {
      content.push({
        label: "Settings",
        to: `/drawing_office/settings`,
      });
    }
    const thisMenuItems = _.filter(custom_forms_menus, (menu) => menu.parent_type == "drawing_office");
    _.each(thisMenuItems, (menu) => {
      const menuObject = {
        label: menu.label,
        icon: `fa ${menu.icon}`,
        to: menu.link,
        external: menu.external_link,
      };
      if (menu.has_children) {
        menuObject.content = _.map(menu.children, (children) => {
          return {
            label: children.label,
            icon: `fa ${children.icon}`,
            to: children.link,
            external: children.external_link,
          };
        });
      }
      content.push(menuObject);
    });
    return drawingOfficeMenu;
  }
};

export default getDrawingOfficeNav;
