import {
  GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS,
  GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENT_GET_BY_GUID
} from "./actions";

export function ProductionStrumisContractDocumentsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS}_FULFILLED`: {
      return action.payload.data;
    }
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENT_GET_BY_GUID}_FULFILLED`: {
      return action.payload.data;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisContractDocumentsReducer } from "modules/production/strumis/contracts/documents/reducer"

// production_strumis_contract_documents:ProductionStrumisContractDocumentsReducer,
