import React, { useEffect } from "react";
import { connect } from "react-redux";

import CustomFormRecordForm from "./form";
import { createCustomFormRecord, getCustomFormRecords } from "../actions";

import { Form } from "react-final-form";
import { getCustomForm, getCustomForms } from "modules/custom_forms/actions.js";

import { getProject } from "modules/projects/actions";
import { getEmployee } from "modules/employees/actions";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

import getReturnAndBreadcrumbs from "../shared";

import arrayMutators from "final-form-arrays";
import { Block, Navbar, Page } from "framework7-react";

function mapStateToProps(state) {
  return {
    projects: state.projects,
    employees: state.employees,
    custom_forms: state.custom_forms,
    custom_form_records: state.custom_form_records,
    v2_maintenance_items: state.v2_maintenance_items,
  };
}

const NewCustomFormRecord = (props) => {
  const {
    f7router,
    f7router: { app },
    custom_forms,
    f7route,
  } = props;
  const { custom_form_id, parent_id } = f7route.params;

  const { projects, employees, custom_form_records, v2_maintenance_items } = props;

  const preloader = app.preloader;

  const custom_form = custom_forms?.[custom_form_id];
  useEffect(() => {
    props.getCustomForms();
    props.getCustomForm(custom_form_id);
  }, [custom_form_id]);

  useEffect(() => {
    if (parent_id && custom_form) {
      if (custom_form?.parent_type === "Project") {
        props.getProject(parent_id);
      }
      if (custom_form?.parent_type === "Employee") {
        props.getEmployee(parent_id);
      }
      if (custom_form?.parent_id && custom_form?.parent_type === "CustomForm") {
        props.getCustomFormRecords(custom_form?.parent_id);
      }
    }
  }, [parent_id, custom_form]);

  let parent_object = null;
  let record_parent_type = parent_object?.parent_type;
  if (custom_form?.parent_type === "Project") {
    parent_object = projects?.[parent_id];
  }
  if (custom_form?.parent_type === "Employee") {
    parent_object = employees?.[parent_id];
  }
  if (custom_form?.parent_type === "CustomForm") {
    parent_object = custom_form_records?.[parent_id];
  }
  if (custom_form?.parent_type === "V2::Maintenance::Category") {
    parent_object = v2_maintenance_items?.[parent_id];
  }

  let returnUrl = `/custom_form/${custom_form?.id}/records`;
  if (parent_id) {
    returnUrl += `?parent_id=${parent_id}`;
  }

  const initialValues = parent_object
    ? {
        parent_id: parent_object?.id,
        parent_type: custom_form.parent_type == "V2::Maintenance::Category" ? "V2::Maintenance::Item" : custom_form?.parent_type,
      }
    : {};

  const createRole = HasRole({ resource_type: "CustomForm::Record", name: ["create", "moderator"] }, true);

  const submitForm = (values) => {
    preloader.show();
    const createRequest = props.createCustomFormRecord(custom_form_id, values);
    createRequest.then(successfullySubmitted);
  };

  const successfullySubmitted = () => {
    preloader.hide();
    app.toast
      .create({
        icon: '<i class="material-icons">check</i>',
        text: `Created new ${custom_form?.name}!`,
        position: "center",
        closeTimeout: 2000,
        on: {
          opened: () => {
            f7router.navigate(returnUrl);
          },
        },
      })
      .open();
  };

  if (!createRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Page>
        <Navbar title={`New ${custom_form?.name}`} backLink backLinkUrl={returnUrl} />
        <Form
          component={CustomFormRecordForm}
          onSubmit={submitForm}
          formLabel="Create"
          cancelFunction={() => {
            f7router.navigate(returnUrl);
          }}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          custom_form={custom_form}
          {...props}
        />
      </Page>
    );
  }
};

export default connect(mapStateToProps, { createCustomFormRecord, getCustomForm, getProject, getEmployee, getCustomForms, getCustomFormRecords })(
  NewCustomFormRecord
);
