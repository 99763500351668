const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_$';

export function compressGUID(inputGUID) {
    const g = inputGUID.replace(/-/g, '');

    const bs = [];
    for (let i = 0; i < g.length; i += 2) {
        bs.push(parseInt(g.slice(i, i + 2), 16));
    }

    function b64(v, l = 4) {
        let result = '';
        for (let i = l - 1; i >= 0; i--) {
            result += chars[(v >> (i * 6)) % 64];
        }
        return result;
    }

    return [b64(bs[0], 2)].concat(
        bs.slice(1, 16).reduce((acc, _, i) => {
            if (i % 3 === 0) {
                acc.push(b64((bs[i + 1] << 16) + (bs[i + 2] << 8) + bs[i + 3]));
            }
            return acc;
        }, [])
    ).join('');
}

export function decompressGUID(g) {
    function b64(v) {
        return [...v].reduce((a, c) => a * 64 + chars.indexOf(c), 0);
    }

    const bs = [b64(g.slice(0, 2))];
    for (let i = 0; i < 5; i++) {
        const d = b64(g.slice(2 + 4 * i, 6 + 4 * i));
        bs.push((d >> 16) & 255, (d >> 8) & 255, d & 255);
    }
    let TeklaGUID = bs.map(b => b.toString(16).padStart(2, '0')).join('');
    return convertToGUID(TeklaGUID);
}


function convertToGUID(input) {
    return input.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5").toUpperCase();
}