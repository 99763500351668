export const TOGGLE_FULLSCREEN = "THEME_OPTIONS/TOGGLE_FULLSCREEN";

export const TOGGLE_RIGHT_SIDEBAR_OPEN = "THEME_OPTIONS/TOGGLE_RIGHT_SIDEBAR_OPEN";

export const toggleFullscreen = (fullscreenEnabled) => ({
  type: TOGGLE_FULLSCREEN,
  fullscreenEnabled,
});


export const toggleRightSidebarOpen = (rightSidebarOpen) => ({
  type: TOGGLE_RIGHT_SIDEBAR_OPEN,
  rightSidebarOpen,
});

export default function reducer(
  state = {
    fullscreen: false,
    rightSidebarOpen: false,
  },
  action
) {
  switch (action.type) {
    case TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullscreenEnabled: action.fullscreenEnabled,
      };
    case TOGGLE_RIGHT_SIDEBAR_OPEN:
      return {
        ...state,
        rightSidebarOpen: action.rightSidebarOpen,
      };

    default:
  }
  return state;
}
