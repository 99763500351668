
import React, { Fragment, useEffect, useState } from 'react';

import { decompressGUID } from 'modules/5_view_helpers/guidConverter';
import { getProductionStrumisContractDocumentGetByGUID } from 'modules/production/strumis/contract/documents/actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getProjectQaPaintInspectionByGUID } from 'modules/project/qa/paint_inspections/actions';
import { PaintInspection } from 'modules/project/qa/paint_inspections/web';
import { Table } from 'reactstrap';

import "modules/project/qa/paint_inspections/web/index.css"

function mapStateToProps(state, ownProps) {
    return {
        project_qa_paint_inspections: state.project_qa_paint_inspections
    }
}

const PaintInspectionsTab = (props) => {
    const [GUID, setGUID] = useState(null)
    const { object, project_qa_paint_inspections, modelID } = props
    useEffect(() => {
        tc_api.viewer.convertToObjectIds(modelID, [object.id]).then((guids) => {
            setGUID(decompressGUID(guids[0]))
        })
    }, [])

    useEffect(() => {
        if (GUID) {
            props.getProjectQaPaintInspectionByGUID(GUID)
            console.log(GUID)
        }
    }, [GUID])

    const thisAssemblyPaintInspections = _.filter(project_qa_paint_inspections, (inspection) => {
        return inspection.assembly.guid == GUID
    })


    return (
        <Fragment>
            {_.isEmpty(project_qa_paint_inspections) && (<div>Loading...</div>)}

            <table className="paint_inspection_table">
                <tr>
                    <th colSpan="13">Inspections</th>
                </tr>
                <tr>
                    <th></th>
                    <th>Inspection #</th>
                    <th>Paint System</th>
                    <th colSpan="3">Coat</th>
                    <th>Inspection Location</th>
                    <th>Average</th>
                    <th>Low</th>
                    <th>High</th>
                    <th># Readings</th>
                    <th>Complies</th>
                    <th>Attachments</th>
                </tr>

                {_.map(thisAssemblyPaintInspections, (inspection) => {
                    return (<PaintInspection paint_inspection={inspection} key={inspection.id} />)
                })}
            </table>

        </Fragment >
    )
}

export default connect(mapStateToProps, { getProjectQaPaintInspectionByGUID })(PaintInspectionsTab);

