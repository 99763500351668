// Basic usage
// import Toggle from "modules/5_view_helpers/web/Toggle.jsx";
// // <Toggle
//   label="Show No Longer Employed"
//   checked={noLongerEmployedToggle}
//   color="success"
//   onClick={() => {
//     props.getEmployees(true);
//     setNoLongerEmployedToggle(!noLongerEmployedToggle);
//   }}
// />

import React, { useState, Fragment } from "react";

import { FormGroup, Label, Input } from "reactstrap";
import cx from "classnames";

const Toggle = (props) => {
  const { checked, label, onLabel, offLabel, color, className, containerClassName, name,containerStyle } = props;

  return (
    <span className={containerClassName} style={containerStyle}>
      <Label className="mr-2 d-print-none" style={{ verticalAlign: "top" }}>
        {label}
      </Label>
      <div
        className={`switch has-switch ${className} mr-2 mb-2 mr-2 d-print-none`}
        data-on-label={onLabel}
        data-off-label={offLabel}
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(!checked);
          }
        }}
      >
        <div
          className={cx("switch-animate", {
            "switch-on": checked,
            "switch-off": !checked,
          })}
        >
          <input type="checkbox" className={className} name={name} checked={checked} />
          <span className={`switch-left bg-${color}`}>{onLabel}</span>
          <label>&nbsp;</label>
          <span className={`switch-right bg-${color}`}>{offLabel}</span>
        </div>
      </div>
    </span>
  );
};

Toggle.defaultProps = {
  name: "",
  onLabel: "On",
  offLabel: "Off",
};

export default Toggle;
