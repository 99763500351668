import React from "react";

const Home = React.lazy(() => import("modules/0_web/Home"));
const CantFindRoute = React.lazy(() => import("./CantFindRoute"));

import ProjectsRoutes from "modules/projects/web/routes";
import ProjectAreaRoutes from "modules/project/areas/web/routes";
import ProjectVariationNumbersRoutes from "modules/project/variation_numbers/web/routes";

// Project Specific Routes
import SiteQADashboard from "modules/project/qa/site/web/routes";

import ProjectQaSiteSiteInstallationRoutes from "modules/project/qa/site/site_installations/web/routes";
import ProjectQaActionItemsStatusesRoutes from "modules/project/qa/action_items/statuses/web/routes";
import ProjectQaActionItemsCategoriesRoutes from "modules/project/qa/action_items/categories/web/routes";

import ProjectQaActionItemsRoutes from "modules/project/qa/action_items/web/routes";
import ProjectQaSiteSurveillancesRoutes from "modules/project/qa/site/surveillances/web/routes";

// Paint
import ProjectQaPaintInspectionsRoutes from "modules/project/qa/paint_inspections/web/routes";
import ProjectTechnicalDetailsPaintSystemsRoutes from "modules/project/technical_details/paint_systems/web/routes";

// Welding Inspections
import ProjectQaWeldingInspectionsRoutes from "modules/project/qa/welding_inspections/web/routes";
import ProjectQaWeldingInspectionsAccessRestrictionsRoutes from "modules/project/qa/welding_inspections/access_restrictions/web/routes";
import ProjectQaWeldingInspectionsSurfaceConditionsRoutes from "modules/project/qa/welding_inspections/surface_conditions/web/routes";
import ProjectQaWeldingInspectionsViewingConditionsRoutes from "modules/project/qa/welding_inspections/viewing_conditions/web/routes";
import ProjectQaWeldingInspectionsWeldingStandardsRoutes from "modules/project/qa/welding_inspections/welding_standards/web/routes";
import ProjectQaWeldingInspectionsInspectionSpecsRoutes from "modules/project/qa/welding_inspections/inspection_specs/web/routes";
import ProjectQaWeldingInspectionsWeldingStagesRoutes from "modules/project/qa/welding_inspections/welding_stages/web/routes";
import ProjectQaWeldingInspectionsWeldingProcessesRoutes from "modules/project/qa/welding_inspections/welding_processes/web/routes";
import ProjectQaWeldingInspectionsWeldingTypesRoutes from "modules/project/qa/welding_inspections/welding_types/web/routes";
import ProjectQaWeldingInspectionsWeldingProceduresRoutes from "modules/project/qa/welding_inspections/welding_procedures/web/routes";
import ProjectQaWeldingInspectionsFailureReasonsRoutes from "modules/project/qa/welding_inspections/failure_reasons/web/routes";
import ProjectQaWeldingInspectionsFilletSizesRoutes from "modules/project/qa/welding_inspections/fillet_sizes/web/routes";
import ProjectQaWeldingInspectionsInspectionCriteriasRoutes from "modules/project/qa/welding_inspections/inspection_criterias/web/routes";

// Assemblies
import ProjectAssembliesRoutes from "modules/project/assemblies/web/routes";

// All Projects Site QA
import ProjectsQASettingsRoutes from "modules/projects/qa/site/web/routes"; // Settings

// Employees Routes
import EmployeesRoutes from "modules/employees/web/routes";
import EmployeeTasksRoutes from "modules/employee/tasks/web/routes";

import EmployeeTrainingRequirementsRoutes from "modules/employee/training_requirements/web/routes";

import DepartmentsRoutes from "modules/departments/web/routes";
import InteralCompanyRoute from "modules/internal_companies/web/routes";
import PositionsRoutes from "modules/positions/web/routes";

import UserManagementsRoutes from "modules/user_managements/web/routes";
import UserGroupsRoutes from "modules/user_groups/web/routes";

// Training Routes
import TrainingModuleRoutes from "modules/training/web/routes";

// Production Routes
import ProductionRoutes from "modules/production/web/routes";

import HireagesRoutes from "modules/hireages/web/routes";

// QA Routes
import QAModuleRoutes from "modules/qa/web/routes";
import PaintSettingsRoutes from "modules/paint/web/routes.js";

// MRP Related
import ProjectSiteDocumentReleasesRoutes from "modules/project/site_document_releases/web/routes";
import ProjectDrawingsRoutes from "modules/project/drawings/web/routes";
import DrawingOfficeRoutes from "modules/drawing_office/web/routes";

// Communications
import ProjectCommunicationsRoutes from "modules/project/communications/web/routes";
import ProjectCommunicationsCustomReferencesRoutes from "modules/project/communications/custom_references/web/routes";
import ProjectBoltedConnectionsRoutes from "modules/project/qa/bolted_connections/web/routes";

// Address Book
import AddressBookRoutes from "modules/address_book/web/routes";

import CustomFormsRoutes from "modules/custom_forms/web/routes";
import CustomFormsMenusRoutes from "modules/custom_forms/menus/web/routes";
import CustomFormRecordsRoutes from "modules/custom_form/records/web/routes";

// Store
import StoreRoutes from "modules/store/web/routes";

// Triggers
import TriggersRoutes from "modules/triggers/web/routes";

// Portal Routes
import PortalRoutes from "modules/portal/web/routes";

const defaultRoutes = [
  { path: "/", exact: true, name: "Home", component: Home },
  { name: "Cant find 404", component: CantFindRoute },
];

let routes = [];

// Project Site QA Settings routes -- Keep above projects
routes = _.concat(routes, ProjectsQASettingsRoutes);

// Project Show Area
routes = _.concat(routes, ProjectAreaRoutes);
routes = _.concat(routes, ProjectVariationNumbersRoutes);
routes = _.concat(routes, ProjectTechnicalDetailsPaintSystemsRoutes);

routes = _.concat(routes, SiteQADashboard);
routes = _.concat(routes, ProjectQaSiteSiteInstallationRoutes);
routes = _.concat(routes, ProjectQaSiteSurveillancesRoutes);

// Paint
routes = _.concat(routes, ProjectQaPaintInspectionsRoutes);

// Welding Related Routes
routes = _.concat(routes, ProjectQaWeldingInspectionsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsAccessRestrictionsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsSurfaceConditionsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsViewingConditionsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsWeldingStandardsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsInspectionSpecsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsWeldingStagesRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsWeldingProcessesRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsWeldingTypesRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsWeldingProceduresRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsFailureReasonsRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsFilletSizesRoutes);
routes = _.concat(routes, ProjectQaWeldingInspectionsInspectionCriteriasRoutes);

// Project Tech Details
routes = _.concat(routes, ProjectTechnicalDetailsPaintSystemsRoutes);

// Action Items + Action Item Settings
routes = _.concat(routes, ProjectQaActionItemsStatusesRoutes);
routes = _.concat(routes, ProjectQaActionItemsCategoriesRoutes);
routes = _.concat(routes, ProjectQaActionItemsRoutes);

// Projects
routes = _.concat(routes, ProjectsRoutes);
routes = _.concat(routes, ProjectAssembliesRoutes);
routes = _.concat(routes, ProjectAssembliesRoutes);

routes = _.concat(routes, EmployeeTasksRoutes);
routes = _.concat(routes, EmployeesRoutes);
routes = _.concat(routes, EmployeeTasksRoutes);
routes = _.concat(routes, EmployeesRoutes);
routes = _.concat(routes, EmployeeTrainingRequirementsRoutes);

routes = _.concat(routes, InteralCompanyRoute);
routes = _.concat(routes, DepartmentsRoutes);
routes = _.concat(routes, PositionsRoutes);

routes = _.concat(routes, UserManagementsRoutes);
routes = _.concat(routes, UserGroupsRoutes);

routes = _.concat(routes, TrainingModuleRoutes);

routes = _.concat(routes, ProductionRoutes);

routes = _.concat(routes, HireagesRoutes);

routes = _.concat(routes, QAModuleRoutes);
routes = _.concat(routes, PaintSettingsRoutes);

// Drawing Office
routes = _.concat(routes, ProjectSiteDocumentReleasesRoutes);
routes = _.concat(routes, ProjectDrawingsRoutes);
routes = _.concat(routes, DrawingOfficeRoutes);

routes = _.concat(routes, ProjectCommunicationsCustomReferencesRoutes);
routes = _.concat(routes, ProjectCommunicationsRoutes);
routes = _.concat(routes, ProjectBoltedConnectionsRoutes)


// Address Book
routes = _.concat(routes, AddressBookRoutes);

routes = _.concat(routes, CustomFormsMenusRoutes)
routes = _.concat(routes, CustomFormsRoutes);
routes = _.concat(routes, CustomFormRecordsRoutes);

// Store
routes = _.concat(routes, StoreRoutes);

// Triggers
routes = _.concat(routes, TriggersRoutes)

// Portal
routes = _.concat(routes, PortalRoutes);



routes = _.concat(routes, defaultRoutes);
export default routes;
