import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Label, FormGroup } from "reactstrap";

import Rows from "./2_Rows";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";

import column_types from "./column_types";
import _ from "lodash";
import { InputField, required, ToggleField } from "modules/4_final_form/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faClone } from "@fortawesome/free-solid-svg-icons";

const PagesAttributes = (props) => {
  const { fields, column_types, disabled, cloneRevision } = props;
  const [activeTab, setActiveTab] = useState(`page[0]`);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const moveLeft = (name, index) => {
    const toIndex = index == 0 ? fields.length - 1 : index - 1;
    fields.swap(index, toIndex);
    // setTimeout is needed to prevent the tab from being set to the wrong value
    setTimeout(() => {
      setActiveTab(`page[${toIndex}]`);
    }, 1);
  };

  const moveRight = (name, index) => {
    const toIndex = index == fields.length - 1 ? 0 : index + 1;
    fields.swap(index, toIndex);

    // setTimeout is needed to prevent the tab from being set to the wrong value
    setTimeout(() => {
      setActiveTab(`page[${toIndex}]`);
    }, 1);
  };

  return (
    <div>
      <h3>
        Pages <FontAwesomeIcon icon={faClone} onClick={cloneRevision} title="Clone Revision" style={{ cursor: "pointer" }} />
      </h3>
      <hr />
      <Nav tabs>
        {fields.map((field, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                onClick={() => {
                  toggleTab(`page[${i}]`);
                }}
                className={activeTab == `page[${i}]` ? "active" : ""}
              >
                {i > 0 && !disabled && (
                  <Button
                    color="light"
                    size="sm"
                    className="mr-1"
                    onClick={() => {
                      moveLeft(field, i);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                )}
                <>{fields.value[i]?.name}</>
                {i < fields.length - 1 && fields.length > 1 && !disabled && (
                  <Button
                    color="light"
                    size="sm"
                    className="ml-1"
                    onClick={() => {
                      moveRight(field, i);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                )}
              </NavLink>
            </NavItem>
          );
        })}
        {!disabled && (
          <NavItem>
            <NavLink
              onClick={() => {
                if (!disabled) {
                  fields.push({ name: `Page ${fields.length + 1}`, show_on_create: true, show_on_update: true });
                  toggleTab(`page[${fields.length}]`);
                }
              }}
            >
              <a className="link">Add Page</a>
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {fields.map((field, i) => {
          const thisField = fields.value[i];
          const rows_attributes = _.filter(thisField.rows_attributes, (row) => !row._destroy);
          return (
            <TabPane key={i} tabId={`page[${i}]`}>
              {_.isEmpty(rows_attributes) && !disabled && (
                <Row>
                  <Col>
                    <Button
                      onClick={() => {
                        if (thisField.id) {
                          fields.update(i, { id: thisField.id, _destroy: "1" });
                        } else {
                          fields.remove(i);
                        }
                        setActiveTab(`page[${fields.length - 2}]`);
                      }}
                      color="danger"
                      size="sm"
                    >
                      Delete Page
                    </Button>
                  </Col>
                </Row>
              )}
              <FormGroup row>
                <Label md={2}>Page Name</Label>
                <Col md={4}>
                  <Field name={`${field}.name`} component={InputField} validate={required} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2}>Show on create</Label>
                <Col md={2}>
                  <Field name={`${field}.show_on_create`} component={ToggleField} />
                </Col>
                <Label md={2}>Show on update</Label>
                <Col md={2}>
                  <Field name={`${field}.show_on_update`} component={ToggleField} />
                </Col>
              </FormGroup>

              <FieldArray name={`${field}.rows_attributes`} component={Rows} column_types={column_types} disabled={disabled} />
            </TabPane>
          );
        })}
      </TabContent>
      {_.isEmpty(fields.value) && disabled && <p>No pages</p>}
    </div>
  );
};

PagesAttributes.defaultProps = {
  column_types: column_types,
};

export default PagesAttributes;
