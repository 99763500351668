import React, { useEffect, useRef, useState } from "react";

import { Card, CardHeader, CardBody, Button, Popover, Tooltip, UncontrolledTooltip, Row, Col } from "reactstrap";
import TrimbleConnectSlidingPane from "./index/TrimbleConnectSlidingPane";
import _ from "lodash";
import { useSelector } from "react-redux";
import Toggle from "modules/5_view_helpers/web/Toggle";

import { compressGUID, decompressGUID } from "modules/5_view_helpers/guidConverter";
import CurrentQASlidingPane from "./index/CurrentQASlidingPane";
import AreasSlidingPane from "./index/AreasSlidingPane";

import * as TrimbleConnectWorkspace from "trimble-connect-workspace-api";
import BoltQaPane from "./index/BoltQaPane";
import BoltListPane from "./index/BoltListPane";
import { selectModelGUIDS } from "./helpers";

const QAIndex = (props) => {
  const current_user = useSelector((state) => state.current_user);
  const projects = useSelector((state) => state.projects);
  const ThemeOptions = useSelector((state) => state.ThemeOptions);
  const fullscreen = ThemeOptions.fullscreen || ThemeOptions.fullscreenEnabled;
  const current_project = projects[current_user?.current_project_id];

  const tc_project_id = current_project?.tc_project_id;
  const tc_model_id = current_project?.tc_model_id;

  const [showInNewWindow, setShowInNewWindow] = useState(false);
  const [InfoOpen, setInfoOpen] = useState(false);
  const [QAOpen, setQAOpen] = useState(false);
  const [AreasOpen, setAreasOpen] = useState(false);
  const [BoltQAOpen, setBoltQAOpen] = useState(false);
  const [BoltListOpen, setBoltListOpen] = useState(false);

  const [absoluteOffsetTop, setAbsoluteOffsetTop] = useState(0);
  const iFrameRef = useRef(null);
  useEffect(() => {
    const updateOffset = () => {
      if (iFrameRef.current) {
        // Get the distance from the top of the viewport and add the scroll position
        const absoluteTop = iFrameRef.current.getBoundingClientRect().top + window.scrollY;
        setAbsoluteOffsetTop(absoluteTop);
      }
    };

    // Set the offset when the component mounts
    updateOffset();

    // Optionally, update the offset on scroll and resize for a responsive layout
    window.addEventListener("scroll", updateOffset);
    window.addEventListener("resize", updateOffset);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("scroll", updateOffset);
      window.removeEventListener("resize", updateOffset);
    };
  }, [fullscreen]);

  async function onTrimbleLoaded() {
    const API = await TrimbleConnectWorkspace.connect(document.getElementById("viewer"), (event, args) => {
      if (event == "viewer.onSelectionChanged") {
        selectionChanged(args);
      }
      // if (event == "viewer.onModelStateChanged") {
      //   if (args?.data?.state === "loaded") {
      //     // getObjects()
      //   }
      // }
    });
    window.tc_api = API;
  }

  // async function getObjects() {
  //   const objects = await tc_api.viewer.getObjects(tc_model_id);
  //   const objectIds = objects?.[0]?.objects.map((object) => object.id);
  //   const properties = await tc_api.viewer.getObjectProperties(tc_model_id, objectIds);
  //   setAllProps(properties);
  // }

  const [selectedObjects, setSelectedObjects] = React.useState([]);
  const selectionChanged = (selectedModelData) => {
    setSelectedObjects([]);

    _.each(selectedModelData?.data, (modelSelection) => {
      const { objectRuntimeIds, modelId } = modelSelection;
      tc_api.viewer.getObjectProperties(modelId, objectRuntimeIds).then((properties) => {
        tc_api.viewer.convertToObjectIds(modelId, objectRuntimeIds).then((guids, index) => {
          const decompressedGuids = _.map(guids, decompressGUID);
          let newObjects = [];

          _.each(properties, (objectProperties, i) => {
            const decompressedGUID = decompressedGuids[i];
            const newObject = { ...objectProperties, guid: decompressedGUID, modelId };
            newObjects.push(newObject);
          });

          // Use the functional form of setSelectedObjects
          setSelectedObjects((prevSelectedObjects) => {
            let updatedSelectedObjects = _.cloneDeep(prevSelectedObjects);

            _.each(newObjects, (newObject) => {
              const findSelectedObjectIndex = _.findIndex(updatedSelectedObjects, { guid: newObject.guid });
              if (findSelectedObjectIndex !== -1) {
                updatedSelectedObjects[findSelectedObjectIndex] = newObject;
              } else {
                updatedSelectedObjects.push(newObject);
              }
            });

            return updatedSelectedObjects;
          });
        });
      });
    });
  };

  let trimbleURL = `https://web.connect.trimble.com/projects/${tc_project_id}/viewer/3d/`;
  if (tc_model_id) {
    trimbleURL += `?modelId=${tc_model_id}`;
  }

  return (
    <Card>
      <CardHeader>
        Quality Assurance
        <Button
          onClick={() => {
            setBoltQAOpen(!BoltQAOpen);
          }}
          style={{ marginLeft: "250px" }}
          color="success"
          id="BoltQA"
          disabled={_.isEmpty(selectedObjects)}
        >
          Do Bolt QA
        </Button>
        <Button
          onClick={() => {
            setBoltListOpen(!BoltListOpen);
          }}
          className="ml-2"
          color="secondary"
          id="BoltList"
        >
          Show Bolt List
        </Button>
        {/* <Button
          color="primary"
          className="m-2"
          onClick={() => {
            setQAOpen(!QAOpen);
          }}
        >
          Show Current QA
        </Button> */}
        <Toggle
          containerStyle={{ paddingLeft: "200px" }}
          containerClassName="ml-2 mt-3 text-right"
          label="Popout"
          checked={showInNewWindow}
          color="success"
          onClick={() => {
            setShowInNewWindow(!showInNewWindow);
          }}
        />
      </CardHeader>
      <CardBody>
        {/* <Col md={2}></Col> */}
        {/* <Row>
          <Col md={2}>
            <Toggle
              label="Show in window"
              checked={showInNewWindow}
              color="success"
              onClick={() => {
                setShowInNewWindow(!showInNewWindow);
              }}
            />
          </Col>
          <Col>
            {/* <Button
              color="primary"
              className="m-2"
              onClick={() => {
                setAreasOpen(!AreasOpen);
              }}
            >
              Areas
            </Button>

            <Button
              onClick={() => {
                setInfoOpen(true);
              }}
              className="m-2"
              color="primary"
              disabled={_.isEmpty(selectedObjects)}
              id="QAButton"
            >
              QA of Selected
            </Button>
            {_.isEmpty(selectedObjects) && (
              <UncontrolledTooltip placement="bottom" target="QAButton">
                Select an object in the model to view QA
              </UncontrolledTooltip>
            )} */}
        {/* </Col> */}
        {/* </Row>  */}

        {tc_project_id && (
          <div>
            <iframe
              ref={iFrameRef}
              src={trimbleURL}
              id="viewer"
              sandbox="allow-scripts allow-modals allow-forms allow-same-origin allow-popups"
              onLoad={() => {
                onTrimbleLoaded();
              }}
              style={{ position: "relative", width: "100%", height: `calc(95vh - ${absoluteOffsetTop}px)` }}
            />
          </div>
        )}

        {!tc_project_id && (
          <div className="text-center">
            <h2>Please set the trimble connect project and model to view the model</h2>
          </div>
        )}
      </CardBody>
      <TrimbleConnectSlidingPane
        isOpen={InfoOpen}
        selectedObjects={selectedObjects}
        modelID={tc_model_id}
        toggle={() => {
          setInfoOpen(!InfoOpen);
        }}
      />

      {QAOpen && (
        <CurrentQASlidingPane
          onSelected={(selectedAssemblies) => {
            const uncompressedGuids = _.map(selectedAssemblies, "guid");
            selectModelGUIDS(uncompressedGuids, tc_model_id);
          }}
          isOpen={QAOpen && tc_api}
          project_id={current_project?.id}
          toggle={() => {
            setQAOpen(!QAOpen);
          }}
          showInNewWindow={showInNewWindow}
          {...props}
        />
      )}

      {AreasOpen && (
        <AreasSlidingPane
          isOpen={AreasOpen}
          project_id={current_project?.id}
          toggle={() => {
            setAreasOpen(!AreasOpen);
          }}
          showInNewWindow={showInNewWindow}
          tc_model_id={tc_model_id}
          {...props}
        />
      )}

      {BoltQAOpen && (
        <BoltQaPane
          isOpen={BoltQAOpen}
          project_id={current_project?.id}
          toggle={() => {
            setBoltQAOpen(!BoltQAOpen);
          }}
          showInNewWindow={showInNewWindow}
          selectedObjects={selectedObjects}
          {...props}
        />
      )}
      {BoltListOpen && (
        <BoltListPane
          isOpen={BoltListOpen}
          project={current_project}
          toggle={() => {
            setBoltListOpen(!BoltListOpen);
          }}
          showInNewWindow={showInNewWindow}
          tc_model_id={tc_model_id}
          {...props}
        />
      )}
    </Card>
  );
};

export default QAIndex;
