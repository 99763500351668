import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import DefaultDataGrid from "modules/5_view_helpers/web/DefaultDataGrid";
import columns from "./columns";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

// Actions
import { getProjectAreas } from "../actions";
import TableTorch from "modules/5_view_helpers/web/TableTorch";

function mapStateToProps(state, ownProps) {
  const {
    project: { id: project_id },
  } = ownProps;

  let project_areas = {};
  if (project_id && _.isNotEmpty(state.project_areas)) {
    project_areas = _.filter(
      state.project_areas,
      (pa) => pa.project_id == project_id
    );
  }

  return {
    project_areas: project_areas,
  };
}

const ProjectAreaIndex = (props) => {
  const { project, onSelect } = props;

  const readRole = HasRole({
    resource_type: "Project::Area",
    name: ["read", "moderator"],
  });
  const createRole = HasRole({
    resource_type: "Project::Area",
    name: ["create", "moderator"],
  });
  const updateRole = HasRole({
    resource_type: "Project::Area",
    name: ["update", "moderator"],
  });

  useEffect(() => {
    if (readRole) {
      props.getProjectAreas(project?.id);
    }
  }, [project]);

  const { project_areas, showCreateButton, showFilters } = props;

  if (!readRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardBody>
          {createRole && showCreateButton && (
            <Row>
              <Col>
                <Link to={`/project/${project?.id}/areas/new`}>
                  <Button color="primary" size="md">
                    <i class_name="fa fa-plus"> Create New Area</i>
                  </Button>
                </Link>
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col>
              <TableTorch
                columns={columns(updateRole)}
                data={project_areas}
                showFilters={showFilters}
                selectable
                onSelect={onSelect}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
};

ProjectAreaIndex.defaultProps = {
  showCreateButton: true,
  showFilters: true,
};

export default connect(mapStateToProps, { getProjectAreas })(ProjectAreaIndex);

