import {
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_WITH_STAGE,
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_FIND_BY_GUID,
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_INCOMPLETE_PAINT,
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_BY_LOTS,
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_VIEW,
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES,
} from "./actions";

export function ProductionStrumisContractMarkInstancesReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES}_FULFILLED`:
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_WITH_STAGE}_FULFILLED`:
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_VIEW}_FULFILLED`:
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_BY_LOTS}_FULFILLED`: {
      let newState = _.clone(state);
      newState = _.merge(newState, _.mapKeys(action.payload.data, "id"));
      return newState;
    }
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_INCOMPLETE_PAINT}_FULFILLED`: {
      return action.payload.data;
    }
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_FIND_BY_GUID}_FULFILLED`: {
      return action.payload.data;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisContractMarkInstancesReducer } from "modules/production/strumis/contracts/reducer"

// production_strumis_contract_mark_instances:ProductionStrumisContractMarkInstancesReducer,
