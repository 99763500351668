import HasRole from "modules/5_view_helpers/HasRole";

const getQANav = (current_project_id) => {

  let items = [];

  items.push({
    icon: "fa fa-home",
    label: "QA Model View",
    to: `/qa`,
  });

  // const assembliesRoles = HasRole({
  //   resource_type: "Project::Assembly",
  //   name: ["read", "moderator"],
  // });

  // if (assembliesRoles) {
  //   items.push({
  //     icon: "fa fa-exclamation",
  //     label: `Assemblies`,
  //     to: `/project/${current_project_id}/assemblies`,
  //   });
  // }

  const paintInspectionReadRole = HasRole({
    resource_type: "Project::Qa::PaintInspection",
    name: ["read", "moderator"],
  });

  if (paintInspectionReadRole) {
    items.push({
      icon: "fa fa-paint-brush",
      label: `Paint Inspections`,
      to: `/project/${current_project_id}/qa/paint_inspections`,
    });
  }

  const weldInspectionReadRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections",
    name: ["read", "moderator"],
  });

  if (weldInspectionReadRole) {
    items.push({
      icon: "fa fa-bolt",
      label: `Welding Inspections`,
      to: `/project/${current_project_id}/qa/welding_inspections`,
    });
  }

  const actionItemsReadRole = HasRole({
    resource_type: "Project::Qa::ActionItem",
    name: ["read", "moderator"],
  });

  if (actionItemsReadRole) {
    items.push({
      icon: "fa fa-exclamation",
      label: `Action Items`,
      to: `/project/qa/action_items`,
    });
  }

  const SiteQARoles = HasRole({ category: "Site QA" });
  if (SiteQARoles) {
    items.push({
      icon: "fa fa-list",
      label: "Site QA",
      to: "/projects/qa/site/dashboard",
    });
  }

  const SurveillanceRoles = HasRole({ category: "Project::Qa::Site::Surveillances" });
  if (SurveillanceRoles && current_project_id) {
    items.push({
      icon: "fa fa-search",
      label: "Surveillance Reports",
      to: `/project/${current_project_id}/qa/site/surveillances`,
    });
  }

  const BoltedConnectionsRoles = HasRole({ resource_type: "Project::Qa::BoltedConnection", name: ["read", "moderator"] });
  if (BoltedConnectionsRoles) {
    items.push({
      icon:"fa fa-bolt",
      label: "Bolted Connections",
      to: `/project/${current_project_id}/qa/bolted_connections`,
    });
  }


  const SettingsRoles = HasRole({ name: "QA_Module_Settings_Access" });
  if (SettingsRoles) {
    items.push({
      icon: "fa fa-cog",
      label: `Settings`,
      to: `/qa/settings`,
    });
  }

  items.push({
    icon: "fa fa-arrow-left",
    label: "Back To Lion",
    to: `/`,
    external: true,
  });

  return items;
};

export default getQANav;
