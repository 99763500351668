import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import AppLayout from "modules/1_layout/web/AppLayout";
import qs from "query-string";

import { toggleFullscreen } from "modules/3_reducers/web/ThemeOptions";
import { useDispatch, useSelector } from "react-redux";


const Router = (props) => {
  const query = qs.parse(location?.search);
  const { fullscreen } = query;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleFullscreen(fullscreen == "true"));
  }, [fullscreen]);

  const fullscreenEnabled = useSelector((state) => state?.ThemeOptions.fullscreenEnabled);

  return (
    <BrowserRouter>
      {/* This is to provide the route props to the applayout */}
      <Route path="/" name="Home" render={(props) => <AppLayout fullscreenEnabled={fullscreenEnabled} forceFullscreen={fullscreen == "true"} {...props} />} />
    </BrowserRouter>
  );
};

export default Router;