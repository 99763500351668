import TableTorch from "modules/5_view_helpers/web/TableTorch";
import React from "react";
import columns from "../columns";

const BoltedConnectionsTable = (props) => {
  const { project_qa_bolted_connections, project_id, showEdit, updateRole } = props;

  const ordered = _.orderBy(project_qa_bolted_connections, ["main_assembly"]);

  return <TableTorch columns={columns(updateRole, project_id, showEdit)} data={ordered}  />;
};

BoltedConnectionsTable.defaultProps = {
  showEdit: true,
};

export default BoltedConnectionsTable;
