import React, { Fragment, useState } from "react";

import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import "./AttachmentViewer.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const imageExt = ["png", "jpg", "jpeg", "gif", "bmp"];

const AttachmentViewer = (props) => {
  const { modal, attachments, emptyMessage, openButton, headerLabel } = props;
  const [modalOpen, setModalOpen] = useState(false);


  if (modal) {
    return (
      <Fragment>
        {_.isEmpty(attachments) && <div style={{ fontStyle: "italic", color: "grey", userSelect: "none" }}>{emptyMessage}</div>}
        {_.isNotEmpty(attachments) && (
          <Fragment>
            {openButton(setModalOpen)}
            {modalOpen && (
              <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} size="lg">
                <ModalHeader toggle={() => setModalOpen(false)}>{headerLabel}</ModalHeader>
                <GalleryView {...props} />
              </Modal>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  } else {
    return <GalleryView {...props} />;
  }
};

AttachmentViewer.defaultProps = {
  emptyMessage: "No files to display",
  modal: false,
  headerLabel: "Attachments",
  openButton: (setModalOpen) => {
    return (
      <a className="link" onClick={() => setModalOpen(true)}>
        Attachments
      </a>
    );
  },
};

export default AttachmentViewer;

const GalleryView = (props) => {
  const { attachments, emptyMessage } = props;

  let imageArray = [];
  let restArray = [];

  _.each(attachments, (a) => {
    let ext = a.name.split(".").pop().toLowerCase();
    const isImage = _.includes(imageExt, ext);

    if (isImage) {
      imageArray.push(a);
    } else {
      restArray.push(a);
    }
  });

  let uiElements;

  // Passed to the Gallery to create custom UI elements
  uiElements = [
    {
      name: "navBar",
      className: "pswp__image-indicator",
      appendTo: "wrapper",
      onInit: (el, pswp) => {
        const navItems = [];
        let item;
        let prevIndex = -1;

        // For each image, create an indicator used to navigate between photos
        _.each(imageArray, (image) => {
          item = document.createElement("img");
          item.className = "pswp__nav-image";
          item.src = image.url;
          item.onclick = (e) => {
            pswp.goTo(navItems.indexOf(e.target));
          };
          el.appendChild(item);
          navItems.push(item);
        });

        // Change the image displayed depending on what item is selected
        pswp.on("change", (a) => {
          if (prevIndex >= 0) {
            navItems[prevIndex].classList.remove("pswp__nav-image--active");
          }
          navItems[pswp.currIndex].classList.add("pswp__nav-image--active");
          prevIndex = pswp.currIndex;
        });
      },
    },
  ];

  return (
    <Fragment>
      {_.isNotEmpty(attachments) && (
        <div className="gallery-open">
          <Gallery uiElements={uiElements}>
            {_.map(imageArray, (image) => {
              let imageData = new Image();
              imageData.src = image.url;
              return (
                <Fragment>
                  <Item original={image.url} width={imageData.width} height={imageData.height}>
                    {({ ref, open }) => <img key={image.id} ref={ref} onClick={open} src={image.url} className="gallery-thumb" title={image.name} />}
                  </Item>
                </Fragment>
              );
            })}
          </Gallery>

          {/* // The other attachments that arent being passed to the gallery */}
          {_.map(restArray,
            (attachment) => {
              let icon;
              let iconColor;
              const ext = attachment.name.split(".").pop().toLowerCase();

              // Set the icon and color depending on the file type
              if (_.includes(ext, "pdf")) {
                icon = faFilePdf;
                iconColor = "#ff1b0e";
              } else if (_.includes(ext, "doc" || "docx")) {
                icon = faFileWord;
                iconColor = "#185abd";
              } else if (_.includes(ext, "xls")) {
                icon = faFileExcel;
                iconColor = "#107c40";
              } else if (_.includes(ext, "ppt")) {
                icon = faFilePowerpoint;
                iconColor = "#c53e1c";
              } else {
                icon = faFile;
              }
              return (
                <a href={attachment.url} style={{ color: "black", textDecoration: "none" }}>
                  <div
                    className="gallery-thumb"
                    style={{ alignItems: "center", display: "inline-block", justifyContent: "center" }}
                    title={attachment.name}
                  >
                    <FontAwesomeIcon icon={icon} color={iconColor} className="file-icon" />
                    <div className="file-name">{attachment.name}</div>
                  </div>
                </a>
              );
            }
          )}
        </div>
      )}
      
      {_.isEmpty(attachments) && (
        <div style={{ fontStyle: "italic", color: "grey", userSelect: "none" }} className="p-5">
          {emptyMessage}
        </div>
      )}
    </Fragment>
  );
};
