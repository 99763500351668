import React from "react"
import SlidingPane from "react-sliding-pane"
import NewWindow from 'react-new-window'
import { useSelector } from "react-redux"

import ProjectAreaIndex from "modules/project/areas/web/index"
import _ from "lodash"

const AreasSlidingPane = (props) => {
    const [activeTab, setActiveTab] = React.useState('paint_inspections')
    const { isOpen, toggle, project_id, showInNewWindow, tc_model_id } = props
    const OpenInType = showInNewWindow ? NewWindow : SlidingPane

    const projects = useSelector((state) => state.projects)

    async function searchModelForArea(areasSelected) {
        if (_.isNotEmpty(areasSelected)) {
            const getObjects = await tc_api.viewer.getObjects(tc_model_id)
            const allObjects = getObjects[0].objects
            const objectIds = allObjects.map((object) => object.id)
            const getObjectProperties = await tc_api.viewer.getObjectProperties(tc_model_id, objectIds)
            console.log(getObjectProperties)
        }
    }


    return (
        <OpenInType isOpen={isOpen} onRequestClose={toggle} onUnload={toggle} width="50%" title="Project Areas" center="screen"
            features={{ width: 1200, height: 800, left: 200, top: 200 }}
        >
            <ProjectAreaIndex
                project={projects[project_id]}
                showCreateButton={false}
                showFilters={false}
                onSelect={searchModelForArea}
            // onSelect={(areas) => {
            //     const phase = areas[0]?.phase
            //     const lot = areas[0]?.lot

            //     if (phase && lot) {
            //         // First get the objects ids from tc_api
            //         // Then get the objects properties
            //         // Then filter the objects based on phase and lot
            //         // Then highlight the objects
            //         tc_api?.viewer?.getObjects(tc_model_id).then((returnObject) => {
            //             const objects = returnObject[0].objects
            //             const objectIds = objects.map((object) => object.id)

            //             console.time('getObjectProperties')
            //             tc_api?.viewer?.getObjectProperties(tc_model_id, objectIds).then((properties) => {
            //                 console.log(properties)
            //                 console.timeEnd('getObjectProperties')
            //             })
            //         })
            //     }
            // }}
            />
        </OpenInType>
    )

}

export default AreasSlidingPane