import axios from "axios";

const prepareData = (data) => { return { project_qa_bolted_connection: data } }

// Plural Actions
export const GET_PROJECT_BOLTED_CONNECTIONS = "get_project_bolted_connections";
export const getProjectBoltedConnections = (project_id) => {
	const url = `/project/${project_id}/qa/bolted_connections.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_BOLTED_CONNECTIONS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_BOLTED_CONNECTION = "create_project_bolted_connection";
export const createProjectBoltedConnection = (project_id, data) => {
	const url = `/project/${project_id}/qa/bolted_connections.json`
	const request = axios({ method: "post", url: url, data: prepareData(data) });
	return {
		type: CREATE_PROJECT_BOLTED_CONNECTION,
		payload: request
	};
};

export const SAVE_MULTIPLE_PROJECT_BOLTED_CONNECTIONS = "save_multiple_project_bolted_connections";
export const saveMultipleProjectBoltedConnections = (project_id, data) => {
	const url = `/project/${project_id}/qa/bolted_connections/save_import.json`
	const request = axios({ method: "post", url: url, data: prepareData(data) });
	return {
		type: SAVE_MULTIPLE_PROJECT_BOLTED_CONNECTIONS,
		payload: request
	};
};

export const GET_PROJECT_BOLTED_CONNECTION = "get_project_bolted_connection";
export const getProjectBoltedConnection = (project_id, id) => {
	const url = `/project/${project_id}/qa/bolted_connections/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_BOLTED_CONNECTION,
		payload: request
	};
};

export const GET_PROJECT_BOLTED_CONNECTION_BY_GUIDS = "get_project_bolted_connection_by_guids";
export const getProjectBoltedConnectionByGuids = (project_id, guids) => {
	const url = `/project/${project_id}/qa/bolted_connections/by_guids.json`
	const request = axios.post(url, { guids: guids });
	return {
		type: GET_PROJECT_BOLTED_CONNECTION_BY_GUIDS,
		payload: request
	};
};

export const UPDATE_PROJECT_BOLTED_CONNECTION = "update_project_bolted_connection";
export const updateProjectBoltedConnection = (project_id, id, data) => {
	const url = `/project/${project_id}/qa/bolted_connections/${id}.json`
	const request = axios({ method: "patch", url: url, data: prepareData(data) });
	return {
		type: UPDATE_PROJECT_BOLTED_CONNECTION,
		payload: request
	};
};

export const DESTROY_PROJECT_BOLTED_CONNECTION = "destroy_project_bolted_connection";
export const destroyProjectBoltedConnection = (project_id, id) => {
	const url = `/project/${project_id}/qa/bolted_connections/${id}.json`
	const request = axios({ method: "delete", url: url });
	return {
		type: DESTROY_PROJECT_BOLTED_CONNECTION,
		payload: request
	};
};
