import React, { Fragment } from "react";
import { Row, Col, Button } from "reactstrap";

import ColDisplay from "./3_ColDisplay";
import ColModal from "./4_ColModal";
import { v4 as uuidv4 } from "uuid";
const ColsAttributes = (props) => {
  const {
    fields,
    fields: { name },
    column_types,
    disabled,
    maybeCanGoDown,
    maybeCanGoUp,
    aboveRowWidthSum,
    belowRowWidthSum,
    moveUp,
    moveDown,
  } = props;

  const [fieldOpen, setFieldOpen] = React.useState({ fieldName: null, fieldIndex: null });
  const [colModalOpen, setColModalOpen] = React.useState(false);

  const toggleColModal = () => {
    setColModalOpen(!colModalOpen);
  };

  const filteredFields = _.filter(fields.value, (f) => !f._destroy);
  const desktopWidthTotal = _.sumBy(filteredFields, (f) => parseInt(f.desktop_width)) || 0;
  const mobileWidthTotal = _.sumBy(filteredFields, (f) => parseInt(f.mobile_width)) || 0;

  const moveLeft = (index) => {
    const toIndex = index == 0 ? fields.length - 1 : index - 1;
    fields.swap(index, toIndex);
  };

  const moveRight = (index) => {
    const toIndex = index == fields.length - 1 ? 0 : index + 1;
    fields.swap(index, toIndex);
  };

  return (
    <Fragment>
      <Row>
        {fields.map((field, thisColIndex) => {
          const thisCol = fields.value[thisColIndex];
          const markedForDestruction = _.get(thisCol, "_destroy");

          const canGoRight = thisColIndex != fields.value.length - 1;
          const canGoLeft = thisColIndex != 0;

          const canGoUp = maybeCanGoUp && aboveRowWidthSum + parseInt(thisCol.desktop_width) <= 12;
          const canGoDown = maybeCanGoDown && belowRowWidthSum + parseInt(thisCol.desktop_width) <= 12;

          if (!markedForDestruction) {
            return (
              <Col key={thisColIndex} md={thisCol?.desktop_width}>
                <ColDisplay
                  name={field}
                  column_types={column_types}
                  columnValues={thisCol}
                  openEdit={() => {
                    if (!disabled) {
                      setFieldOpen({ fieldName: field, fieldIndex: thisColIndex });
                      toggleColModal(true);
                    }
                  }}
                  disabled={disabled}
                  canGoRight={canGoRight}
                  canGoLeft={canGoLeft}
                  canGoUp={canGoUp}
                  canGoDown={canGoDown}
                  moveLeft={() => moveLeft(thisColIndex)}
                  moveRight={() => moveRight(thisColIndex)}
                  moveDown={() => moveDown(thisColIndex)}
                  moveUp={() => moveUp(thisColIndex)}
                />
              </Col>
            );
          }
        })}
        {desktopWidthTotal < 12 && !disabled && (
          <Col>
            <Button
              onClick={() => {
                fields.push({ uid: uuidv4(), desktop_width: 12 }); // Add a uid to make it easier to filter out, only needed on the front end.
                setFieldOpen({
                  fieldName: `${name}[${fields.length}]`,
                  fieldIndex: fields.length,
                });
                toggleColModal();
              }}
            >
              Add Col
            </Button>
          </Col>
        )}
      </Row>
      <ColModal column_types={column_types} isOpen={colModalOpen} toggle={toggleColModal} fieldOpen={fieldOpen} fields={fields} {...props} />
    </Fragment>
  );
};

export default ColsAttributes;
