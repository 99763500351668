import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Progress,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

// Actions
import { getProjectQaPaintInspections } from "../actions";
import { toggleRightSidebarOpen } from "modules/3_reducers/web/ThemeOptions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSync, faTable } from "@fortawesome/free-solid-svg-icons";

import qs from "query-string";

import "./index.css";
import { Field, Form } from "react-final-form";
import { InputField, ToggleField } from "modules/4_final_form/web";
import AttachmentViewer from "modules/5_view_helpers/web/AttachmentViewer";
import classnames from "classnames";
import { getPaintLocations } from "modules/paint/locations/actions";

function mapStateToProps(state, props) {
  const project_id = props.match?.params?.project_id || props.project_id;

  return {
    project: state.projects[project_id],
    this_project_qa_paint_inspections: _.filter(state.project_qa_paint_inspections, (pi) => pi.project_id == project_id),
    rightSidebarOpen: state.ThemeOptions.rightSidebarOpen,
    paint_locations: state.paint_locations,
  };
}

const ProjectQaPaintInspectionsIndex = (props) => {
  const [selected, setSelected] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const {
    paint_locations,
    showNewButton,
    showSelection,
    onSelected,
  } = props;

  const project_id = props.match?.params?.project_id || props.project_id;

  useEffect(() => {
    if (showSelection && onSelected) {
      onSelected(selected)
    }
  }, [selected])



  useEffect(() => {
    props.getPaintLocations();
  }, []);

  useEffect(() => {
    if (_.isEmpty(activeTab) && _.isNotEmpty(paint_locations)) {
      const findDefault = _.find(paint_locations, (pl) => pl.default);
      setActiveTab(findDefault?.id);
    }
  }, [paint_locations]);

  const { this_project_qa_paint_inspections } = props;
  useEffect(() => {
    if (project_id && _.isEmpty(this_project_qa_paint_inspections)) {
      props.getProjectQaPaintInspections(project_id);
    }
  }, [project_id]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs>
        {_.map(paint_locations, (paint_location) => (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === paint_location.id })}
              onClick={() => {
                toggle(paint_location.id);
              }}
            >
              {paint_location.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {_.map(paint_locations, (paint_location) => {
          const this_location_paint_inspections = _.filter(this_project_qa_paint_inspections, (pi) => pi.location_id == paint_location.id);

          return (
            <TabPane tabId={paint_location?.id}>
              <ProjectQaPaintInspectionsByLocation
                showNewButton={showNewButton}
                paint_inspections={this_location_paint_inspections}
                project_id={project_id}
                showSelection={showSelection}
                setSelected={setSelected}
                selected={selected}
                {...props}
              />
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

ProjectQaPaintInspectionsIndex.defaultProps = {
  showNewButton: true,
}

export default connect(mapStateToProps, { getProjectQaPaintInspections, toggleRightSidebarOpen, getPaintLocations })(ProjectQaPaintInspectionsIndex);

const ProjectQaPaintInspectionsByLocation = (props) => {
  const { project_id, project, paint_inspections, showNewButton, showSelection, selected, setSelected } = props;

  const [filters, setFilters] = useState({});
  const handleFilters = (event) => {
    let newFilters = _.clone(filters);
    newFilters[event.target.name] = event.target.value;
    setFilters(newFilters);
  };

  const [compliantFilter, setCompliantFilter] = useState(false);

  const [dateFilter, setDateFilter] = useState("");

  const readRole = HasRole({ resource_type: "Project::Qa::PaintInspection", name: ["read", "moderator"] });
  const createRole = HasRole({ resource_type: "Project::Qa::PaintInspection", name: ["create", "moderator"] });
  const updateRole = HasRole({ resource_type: "Project::Qa::PaintInspection", name: ["update", "moderator"] });

  let filtered_project_qa_paint_inspections = _.cloneDeep(paint_inspections);
  _.each(filters, (value, filterName) => {
    if (value && filterName) {
      filtered_project_qa_paint_inspections = _.filter(filtered_project_qa_paint_inspections, (dn) =>
        _.includes(dn[filterName] && dn[filterName].toLowerCase(), value.toLowerCase())
      );
    }
  });

  // Date Filtering
  if (dateFilter) {
    filtered_project_qa_paint_inspections = _.filter(filtered_project_qa_paint_inspections, (pi) => {
      return fd(pi.created_at).includes(dateFilter);
    });
  }

  filtered_project_qa_paint_inspections = _.sortBy(filtered_project_qa_paint_inspections, (pi) => pi.assembly.display_name_full);

  let PaintInspectionsByGuid = _.groupBy(filtered_project_qa_paint_inspections, "assembly.guid");
  const [reportModalOpen, setReportModalOpen] = useState(false); // Report Modal
  const toggleReportModal = () => setReportModalOpen(!reportModalOpen);

  if (!readRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Fragment>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h3>{project?.display_name} Paint inspections</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {createRole && showNewButton && (
                <Col>
                  <Link to={`/project/${project_id}/qa/paint_inspections/new`}>
                    <Button color="primary" size="md">
                      <i className="fa fa-plus"> Create New Paint inspection</i>
                    </Button>
                  </Link>
                </Col>
              )}
            </Row>

            <Row>
              <Col className="text-right">
                <Button
                  onClick={() => {
                    toggleReportModal();
                  }}
                >
                  Overall Report <FontAwesomeIcon icon={faTable} />
                </Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                Reload{" "}
                <FontAwesomeIcon
                  icon={faSync}
                  style={{ cursor: "pointer", paddingTop: "12px" }}
                  onClick={() => {
                    props.getProjectQaPaintInspections(project_id);
                  }}
                />
                <table className="paint_inspection_table">
                  <thead>
                    <tr>
                      {showSelection && <th style={{ width: "30px" }}>
                        <input type="checkbox" onChange={(e) => {
                          if (e.target.checked) {
                            setSelected(_.map(PaintInspectionsByGuid, (pi) => pi[0].assembly))
                          } else {
                            setSelected([])
                          }
                        }}
                        />
                      </th>}
                      <th style={{ width: "40px" }}></th>
                      <th colSpan="4">
                        <input value={filters?.marks} placeholder="Assembly Search" onChange={handleFilters} name="assembly_display_name_full" />
                      </th>
                      <th colSpan="3">
                        <Row>
                          <Col>
                            <input value={filters?.marks} placeholder="Phase Search" onChange={handleFilters} name="assembly_phase" />
                          </Col>
                          <Col>
                            <input value={filters?.marks} placeholder="Lot Search" onChange={handleFilters} name="assembly_lot" />
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <input value={dateFilter} placeholder="Date Search" onChange={(e) => setDateFilter(e.target.value)} />
                      </th>
                      <th colSpan="2"></th>
                      <th>
                        <select
                          placeholder="select"
                          value={compliantFilter}
                          onChange={(e) => {
                            setCompliantFilter(e.target.value);
                          }}
                        >
                          <option selected value="">
                            All
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </th>
                      <th>Count :{_.keys(PaintInspectionsByGuid).length}</th>
                    </tr>
                    <tr>
                      {showSelection && <th></th>}
                      <th style={{ width: "40px" }}></th>
                      <th colSpan="4">Assembly Mark</th>
                      <th colSpan="3">Phase/Lot</th>
                      <th>Last Inspection Date</th>
                      <th colSpan="2"># of Paint Inspections</th>
                      <th>Complaint</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(PaintInspectionsByGuid, (paint_inspections, key) => {
                      return (
                        <AssemblyRow
                          key={key}
                          paint_inspections={paint_inspections}
                          compliantFilter={compliantFilter}
                          createRole={createRole}
                          updateRole={updateRole}
                          showSelection={showSelection}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Form
          onSubmit={(values) => {
            let combineFilters = { ...filters };
            if (compliantFilter) {
              combineFilters["compliant"] = compliantFilter;
            }

            let pdfURL = "paint_inspections.pdf";
            if (_.isNotEmpty(combineFilters)) {
              pdfURL += "?" + qs.stringify(combineFilters);
            }
            if (_.isNotEmpty(values)) {
              if (_.isNotEmpty(combineFilters)) {
                pdfURL += "&";
              } else {
                pdfURL += "?";
              }

              pdfURL += qs.stringify(values);
            }

            window.open(pdfURL);
          }}
          render={({ handleSubmit }) => (
            <Modal isOpen={reportModalOpen} toggle={toggleReportModal}>
              <ModalBody>
                <h5>Select fields</h5>
                <hr />
                <form id="reportForm" onSubmit={handleSubmit}>
                  <FormGroup row>
                    <Label md={6}>Title</Label>
                    <Col md={6}>
                      <Field component={InputField} defaultValue="Paint Inspection Report" name="title" type="textarea" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={6}>Reqd DFT</Label>
                    <Col md={6}>
                      <Field component={ToggleField} defaultValue={true} name="show_reqd_dft" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={6}>Avg DFT</Label>
                    <Col md={6}>
                      <Field component={ToggleField} defaultValue={true} name="show_avg_dft" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={6}>Low DFT</Label>
                    <Col md={6}>
                      <Field component={ToggleField} defaultValue={true} name="show_low_dft" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={6}>High DFT</Label>
                    <Col md={6}>
                      <Field component={ToggleField} defaultValue={true} name="show_high_dft" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={6}># of readings</Label>
                    <Col md={6}>
                      <Field component={ToggleField} defaultValue={true} name="show_number_of_readings" />
                    </Col>
                  </FormGroup>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                  Get Report
                </Button>
              </ModalFooter>
            </Modal>
          )}
        />
      </Fragment>
    );
  }
};

const AssemblyRow = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { paint_inspections, updateRole, createRole, compliantFilter, showSelection, selected, setSelected } = props;
  const assembly = paint_inspections[0].assembly;

  let display_paint_inspections = _.clone(paint_inspections);
  if (compliantFilter) {
    if (compliantFilter == "yes") {
      display_paint_inspections = _.filter(display_paint_inspections, (pi) => pi.complies);
      // Dont render anything if there are no passed inspections
      if (!_.some(display_paint_inspections, (pi) => pi.complies)) {
        return false;
      }
    } else if (compliantFilter == "no") {
      display_paint_inspections = _.filter(display_paint_inspections, (pi) => !pi.complies);
      // Dont render anything if there are no failed inspections
      if (!_.some(display_paint_inspections, (pi) => !pi.complies)) {
        return false;
      }
    }
  }

  const complaint_count = _.countBy(display_paint_inspections, (pi) => pi.complies);
  const last_date = fd(_.last(display_paint_inspections)?.created_at)

  const isSelected = _.find(selected, (s) => s.guid == assembly.guid);
  return (
    <Fragment>
      <tr style={{ backgroundColor: isSelected ? "lightblue" : "" }}>
        {showSelection && (<td>
          <input type="checkbox" onChange={(e) => {
            if (e.target.checked) {
              setSelected([...selected, assembly])
            } else {
              setSelected(_.filter(selected, (s) => s.guid != assembly.guid))
            }
          }}
            checked={isSelected}
          />
        </td>)}
        <td
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? "-" : "+"}
        </td>
        <td colSpan="4">{assembly.display_name_full}</td>
        <td colSpan="3">
          {assembly.phase}/{assembly.lot}
        </td>
        <td>{last_date}</td>
        <td colSpan="2">{paint_inspections.length}</td>
        <td>
          <Progress multi>
            <Progress bar color="danger" value={(complaint_count.false || 0) * 100}>
              {complaint_count?.false}
            </Progress>
            <Progress bar color="success" value={(complaint_count.true || 0) * 100}>
              {complaint_count?.true}
            </Progress>
          </Progress>
        </td>
        <td>{createRole && <Link to={`/project/${assembly.project_id}/qa/paint_inspections/new?selectedGUID=${assembly.guid}`}>Reinspect</Link>}</td>
      </tr>

      {isOpen && (
        <Fragment>
          <tr>
            <th colSpan="13">Inspections</th>
          </tr>
          <tr>
            <th></th>
            <th>Inspection #</th>
            <th>Paint System</th>
            <th colSpan="4">Coat</th>
            <th>Inspection Location</th>
            <th>Date</th>
            <th>Average</th>
            <th>Low</th>
            <th>High</th>
            <th># Readings</th>
            <th>Complies</th>
            <th>Attachments</th>
          </tr>
          {_.map(display_paint_inspections, (paint_inspection, i) => {
            return <PaintInspection key={i} project_id={assembly.project_id} paint_inspection={paint_inspection} updateRole={updateRole} />;
          })}

          <tr>
            <th colSpan="13"></th>
          </tr>
        </Fragment>
      )}
    </Fragment>
  );
};

export const PaintInspection = (props) => {
  const {
    paint_inspection,
    paint_inspection: { section_readings_attributes },
    project_id,
    updateRole,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <tr>
        {paint_inspection.inspect_by_sections ? (
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? "-" : "+"}
          </td>
        ) : (
          <td></td>
        )}
        <td>{paint_inspection.number}</td>
        <td>
          {paint_inspection.paint_system_display_code}
          {paint_inspection.paint_system_is_multi_system && ` -> ${paint_inspection.child_paint_system_display_code}`}
        </td>
        <td colSpan="4">{paint_inspection.coat_display_name}</td>
        <td>{paint_inspection.location_name}</td>
        <td>{fd(paint_inspection.created_at)}</td>
        <td>{!paint_inspection.inspect_by_sections && paint_inspection.average_dft}</td>
        <td>{!paint_inspection.inspect_by_sections && paint_inspection.low_dft}</td>
        <td>{!paint_inspection.inspect_by_sections && paint_inspection.high_dft}</td>
        <td>{!paint_inspection.inspect_by_sections && paint_inspection.readings}</td>
        <td>{paint_inspection.complies ? "Yes" : "No"}</td>
        <td>
          <AttachmentViewer headerLabel={`Attachments`} id={paint_inspection?.id} attachments={paint_inspection.attachments_attributes} modal />
        </td>
        <td>
          <a href={`/project/${paint_inspection.project_id}/qa/paint_inspections/${paint_inspection.id}.pdf`} target="_blank">
            Report
          </a>
        </td>
        <td>{updateRole && <Link to={`/project/${project_id}/qa/paint_inspections/${paint_inspection.id}/edit`}>Edit</Link>} </td>
      </tr>

      {isOpen && (
        <Fragment>
          {_.map(section_readings_attributes, (section_reading) => (
            <tr>
              <td className="pl-3"></td>
              <td></td>
              <td colSpan="5">
                <b>Section : {section_reading.section_size}</b>
              </td>
              <td>{section_reading.average_dft}</td>
              <td>{section_reading.low_dft}</td>
              <td>{section_reading.high_dft}</td>
              <td>{section_reading.readings}</td>
              <td>{section_reading.complies ? "Yes" : "No"}</td>
              <td></td>
            </tr>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};
