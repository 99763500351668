/**
 * A table component that allows filtering, sorting, and customization of columns and data.
 *
 * @param {Object} props - The props object.
 * @param {string} [props.tableClassName="table_torch"] - The class name for the table.
 * @param {Object} [props.tableStyle={}] - The style object for the table.
 * @param {boolean} [props.selectable=true] - If selectable or not used for selecting rows. Use onSelect to get the selected rows.
 * @param {Function} [props.onSelect] - The function that returns the selected rows.
 * @param {boolean} [props.showFilters=true] - Whether to show filters for each column.
 * @param {boolean} [props.showCount=true] - Whether to show the count of the data.
 * @param {boolean} [props.showHeaders=true] - Whether to show the table headers.
 * @param {Object} [props.rowStyle={}] - The style object for each row.
 * @param {string} [props.className=""] - The class name for the table.
 * @param {Array} props.columns - The array of column objects.
 * @param {Array} props.data - The array of data objects.
 * @param {Function} [props.childComponent=null] - The function that returns the child component for each row.
 * @returns {JSX.Element} - The table component.
 */

// Example usage:
// import { TableTorch } from "modules/5_view_helpers/web";
//
// const columns = [
//   {
//     header: "Name",
//     dataKey: "name",
//     sort: true,
//     cellRenderer: (dataCell, dataRow) => {
//       return <div>{dataCell}</div>;
//     },
//   },
//   {
//     header: "Email",
//     dataKey: "email",
//     sort: true,
//     cellRenderer: (dataCell, dataRow) => {
//       return <div>{dataCell}</div>;
//     },
//   },
//   {
//     header: "Phone",
//     dataKey: "phone",
//     sort: true,
//     cellRenderer: (dataCell, dataRow) => {
//       return <div>{dataCell}</div>;
//     },
//   },
// ];
//
// const data = [
//   {
//     name: "John Doe",
//     email: "john.doe@gmail",
//     phone: "123-456-7890",
//   },
//   {
//     name: "Jane Doe",
//     email: "jane.doe@gmail",
//     phone: "123-456-7890",
//   },
// ];
//
// const Example = (props) => {
//   return (
//     <TableTorch
//       columns={columns}
//       data={data}
//       showFilters={true}
//       showCount={true}
//       showHeaders={true}
//       selectable={true}
//       rowStyle={(dataRow) => {
//         return { backgroundColor: dataRow.name === "John Doe" ? "red" : "white" };
//       }}
//       childComponent={(dataRow) => {
//         return <div>{dataRow.name}</div>;
//       }}
//     />
//   );
// };
//
// export default Example;

import React, { Fragment, useState, useMemo, useEffect } from "react";

import "./TableTorch.css";

const TableTorch = (props) => {
  const [filterValues, setFilterValues] = useState([]); // Filter values
  const [sortObject, setSortObject] = useState({}); // Sort values
  const [selectedRows, setSelectedRows] = useState([]); // Selected rows

  const { tableClassName, tableStyle } = props; // Style related props
  const { showFilters, showCount, showHeaders, rowStyle, className, selectable, onSelect } = props; // Table configuration props
  const { columns, data, childComponent } = props; // Data related props

  // Validate the columns prop.
  if (!data) {
    throw new Error("TableTorch: data prop is required");
  }
  if (!columns) {
    throw new Error("TableTorch: columns prop is required");
  }

  // Filter the data based on the filter values.
  const onFilterChange = (event, dataKey) => {
    const { value } = event.target;
    setFilterValues({ ...filterValues, [dataKey]: value });
  };
  let dataToDisplay = _.toArray(data);
  if (filterValues) {
    _.each(filterValues, (filter, dataKey) => {
      dataToDisplay = _.filter(dataToDisplay, (dataRow) => {
        if (filter) {
          const findColumn = columns.find((col) => col.dataKey === dataKey);
          let dataCell;
          if (findColumn.cellRenderer) {
            dataCell = findColumn.cellRenderer(_.get(dataRow, dataKey), dataRow);
          } else {
            dataCell = _.get(dataRow, dataKey);
          }

          return _.includes(dataCell?.toString().toLowerCase(), filter?.toString().toLowerCase());
        } else {
          return true;
        }
      });
    });
  }
  // Filter the data based on the filter values.

  // Sort the data based on the sort values.
  if (sortObject) {
    const { name, direction } = sortObject;
    if (name && direction) {
      if (sortObject.sortValueFunc) {
        dataToDisplay = _.orderBy(dataToDisplay, (dataRow) => sortObject.sortValueFunc(_.get(dataRow, name), dataRow), [direction]);
      } else {
        dataToDisplay = _.orderBy(dataToDisplay, [name], [direction]);
      }
    }
  }

  // Calculate the count of the data to display.
  const count = useMemo(() => {
    return dataToDisplay.length;
  }, [dataToDisplay]);
  // Calculate the count of the data to display.

  // Send onSelect event to parent component
  useEffect(() => {
    if (onSelect) {
      onSelect(selectedRows);
    }
  }, [selectedRows]);

  return (
    <Fragment>
      <small><b>Count : {count}</b></small>
      <table className={`${tableClassName} ${className}`} style={tableStyle}>
        <thead>
          {showHeaders && (
            <RenderHeader
              columns={columns}
              showFilters={showFilters}
              showCount={showCount}
              count={count}
              onFilterChange={onFilterChange}
              childComponent={childComponent}
              sortObject={sortObject}
              setSortObject={setSortObject}
              selectable={selectable}
              onSelect={(e) => {
                if (e.target.checked) {
                  setSelectedRows(dataToDisplay);
                } else {
                  setSelectedRows([]);
                }
              }}
            />
          )}
        </thead>
        <tbody>
          {_.map(dataToDisplay, (dataRow, i) => {
            return (
              <RenderRow
                key={i}
                dataRow={dataRow}
                columns={columns}
                childComponent={childComponent}
                rowStyle={rowStyle}
                selectable={selectable}
                selectedRows={selectedRows}
                onSelect={(e) => {
                  if (e.target.checked) {
                    setSelectedRows([...selectedRows, dataRow]);
                  } else {
                    setSelectedRows(selectedRows.filter((row) => row !== dataRow));
                  }
                }}
              />
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

TableTorch.defaultProps = {
  columns: [],
  tableClassName: "table_torch",
  childOptions: {},
  showFilters: true,
  showCount: true,
  showHeaders: true,
  selectable: false,
};

export default TableTorch;

const RenderHeader = (props) => {
  const { columns, showFilters, onFilterChange, childComponent, sortObject, setSortObject, selectable, onSelect } = props;

  return (
    <Fragment>
      {showFilters && (
        <tr>
          {selectable && <th style={{ width: "3%", textAlign: "center" }}></th>}
          {childComponent && <th style={{ width: "5%" }}></th>}
          {_.map(columns, (col, i) => {
            return (
              <th key={i} className={col.className} style={col.headerStyle} colSpan={col.colSpan}>
                {!col.hideFilter && (
                  <input
                    type="string"
                    onChange={(e) => {
                      onFilterChange(e, col.dataKey);
                    }}
                    placeholder={col.filterPlaceholder || `Search ${col.header || col.dataKey}...`}
                    style={{ width: "100%" }}
                  />
                )}
              </th>
            );
          })}
        </tr>
      )}

      <tr>
        {selectable && (
          <th style={{ width: "3%", textAlign: "center" }}>
            <input type="checkbox" onChange={onSelect} />
          </th>
        )}
        {childComponent && <th style={{ width: "5%" }}></th>}
        {_.map(columns, (col, i) => {
          let colStyle = { ...col.style };
          let onClickHandler = null;
          if (col.sort) {
            colStyle = { ...colStyle, cursor: "pointer" };
            onClickHandler = (e) => {
              let newSortObject;
              if (sortObject.name == col.dataKey) {
                newSortObject = { name: col.dataKey, direction: sortObject.direction == "asc" ? "desc" : "asc" };
              } else {
                newSortObject = { name: col.dataKey, direction: "asc" };
              }
              if (col.sortValueFunc) {
                newSortObject.sortValueFunc = col.sortValueFunc;
              }
              setSortObject(newSortObject);
            };
          }

          return (
            <th key={i} className={col.className} style={colStyle} colSpan={col.colSpan} onClick={onClickHandler}>
              {col.header || col.dataKey}
              {col.sort && sortObject.name === col.dataKey && <span style={{ marginLeft: "5px" }}>{sortObject.direction === "asc" ? "▲" : "▼"}</span>}
            </th>
          );
        })}
      </tr>
    </Fragment>
  );
};

const RenderRow = (props) => {
  const { dataRow, columns, rowStyle, childComponent, selectable, selectedRows, onSelect } = props;
  const [isOpen, setIsOpen] = useState(false);

  let calculatedStyle = (_.isFunction(rowStyle) && rowStyle(dataRow)) || {};
  const selected = selectedRows.includes(dataRow);
  if (selected) {
    calculatedStyle = { ...calculatedStyle, backgroundColor: "lightblue" };
  }

  let hasChildData = false;
  if (childComponent) {
    hasChildData = childComponent(dataRow);
  }

  return (
    <Fragment>
      <tr style={calculatedStyle}>
        {selectable && (
          <td style={{ width: "3%", textAlign: "center" }}>
            <input type="checkbox" onChange={onSelect} checked={selected} />
          </td>
        )}
        {/* // If the table has child columns, render a column to show/hide the child data. */}
        {childComponent && (
          <td
            style={{ cursor: hasChildData ? "pointer" : "" }}
            onClick={() => {
              hasChildData && setIsOpen(!isOpen);
            }}
          >
            {hasChildData && <div>{isOpen ? "-" : "+"}</div>}
          </td>
        )}
        {_.map(columns, (col, i) => {
          // Use lodash to get the data for the cell. This allows for nested data keys.
          const dataCell = _.get(dataRow, col.dataKey);

          // If the column has a cellRenderer, use it to render the cell.
          if (col.cellRenderer) {
            return (
              <td key={i} className={col.className} style={col.cellStyle} colSpan={col.colSpan}>
                {col.cellRenderer(dataCell, dataRow)}
              </td>
            );
          } else {
            // Otherwise, just render the data.
            return (
              <td key={i} className={col.className} style={col.cellStyle} colSpan={col.colSpan}>
                {dataCell}
              </td>
            );
          }
        })}
      </tr>
      {isOpen && (
        <tr>
          <td colSpan={columns.length + 1}>{childComponent(dataRow)}</td>
        </tr>
      )}
    </Fragment>
  );
};
