import React, { useEffect } from "react";
import { Page, BlockTitle, List, ListItem } from "framework7-react";

import HasRole from "modules/5_view_helpers/HasRole";

import QASidebar from "modules/project/qa/mobile/QASidebar";
import StoreSidebar from "modules/store/mobile/StoreSidebar";
import { connect } from "react-redux";
import { getCustomForms } from "modules/custom_forms/actions";

const mapStateToProps = (state) => ({
  custom_forms: state.custom_forms,
});

const AppSidebar = (props) => {
  const { custom_forms } = props;

  useEffect(() => {
    props.getCustomForms();
  }, []);

  const drawingsReadRole = HasRole(
    {
      resource_type: "Project::Drawing",
      name: ["read", "moderator"],
    },
    true
  );
  const customFormsRoles = HasRole({ resource_type: "CustomForm", name: ["read", "moderator"] }, true);
  const StoreModuleRole = HasRole({ name: "Store_Module_Access" }, true);

  let display_custom_forms = _.filter(custom_forms, (form) => {
    return (form.include_parent_index_page || !form.parent_type) && !form.archived && form.add_to_main_menu;
  });

  // Check if it show a different sidebar nav
  const { pathname } = location;
  const isQA = _.includes(pathname, "qa");
  const isStore = _.includes(pathname, "store");
  if (isQA) {
    return <QASidebar {...props} />;
  }
  if (isStore) {
    return <StoreSidebar {...props} />;
  }
  // Check if it show a different sidebar nav

  return (
    <Page>
      <BlockTitle>Main Navigation</BlockTitle>
      <List>
        <ListItem link="/project/qa" title="Quality Assurance" panelClose></ListItem>
        {drawingsReadRole && <ListItem link="/project/drawings" title="Drawings" panelClose></ListItem>}
        {StoreModuleRole && <ListItem link="/store" title="Store" panelClose />}
        {customFormsRoles &&
          _.map(display_custom_forms, (custom_form, i) => {
            return <ListItem key={i} link={`/custom_form/${custom_form?.id}/records`} title={custom_form?.name} panelClose />;
          })}
      </List>
    </Page>
  );
};

export default connect(mapStateToProps, { getCustomForms })(AppSidebar);
