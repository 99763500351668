import {
  GET_PROJECT_QA_PAINT_INSPECTIONS,
  GET_PROJECT_QA_PAINT_INSPECTION,
  GET_PROJECT_QA_PAINT_INSPECTION_BY_GUID,
  CREATE_PROJECT_QA_PAINT_INSPECTION,
  UPDATE_PROJECT_QA_PAINT_INSPECTION,
  DESTROY_PROJECT_QA_PAINT_INSPECTION,
  GET_PROJECT_QA_PAINT_INSPECTIONS_BY_ASSEMBLY_GUID,
} from "./actions";

export function ProjectQaPaintInspectionsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PROJECT_QA_PAINT_INSPECTIONS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }

    case `${GET_PROJECT_QA_PAINT_INSPECTIONS_BY_ASSEMBLY_GUID}_FULFILLED`: {
      let newState = _.clone(state);
      const newItems = action.payload.data;
      _.each(newItems, (newItem) => {
        const id = _.get(newItem, "id");
        // Checks for a change then changes the state
        if (!_.isEqual(newState[id], newItem)) {
          newState[id] = newItem;
        }
      });
      return newState;
    }
    case `${GET_PROJECT_QA_PAINT_INSPECTION_BY_GUID}_FULFILLED`: {
      let newState = _.cloneDeep(state);
      return _.merge(newState, _.mapKeys(action.payload.data, "id"));
    }
    case `${GET_PROJECT_QA_PAINT_INSPECTION}_FULFILLED`:
    case `${CREATE_PROJECT_QA_PAINT_INSPECTION}_FULFILLED`:
    case `${UPDATE_PROJECT_QA_PAINT_INSPECTION}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_QA_PAINT_INSPECTION}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectQaPaintInspectionsReducer } from "modules/project/qa/paint_inspections/reducer"

// project_qa_paint_inspections:ProjectQaPaintInspectionsReducer,
