function toSentence(array, options = {}) {
    if (_.isArray(array) === false) {
        return false;
    }

    const defaultOptions = {
        wordsConnector: ', ',
        twoWordsConnector: ' and ',
        lastWordConnector: ', and '
    };

    options = { ...defaultOptions, ...options };

    if (array.length === 0) return '';
    if (array.length === 1) return array[0];
    if (array.length === 2) return array.join(options.twoWordsConnector);

    return array.slice(0, -1).join(options.wordsConnector) + options.lastWordConnector + array.slice(-1);
}

export default toSentence;