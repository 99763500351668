import moment from "moment";
import React from "react";

import { Link } from "react-router-dom";

const columns = (updateRole = false) => {
  let returnArray = [
    {
      header: "Name",
      dataKey: "display_name",
      dataFilter: "text",
    },
    {
      header: "Site Installation QA",
      dataKey: "site_installation_qa",
      cellRenderer: (cell, row) => <div>{cell ? "Yes" : "No"}</div>,
      dataFilter: "select",
    },
    {
      header: "Checklist Items",
      dataKey: "checklist_items_string",
      cellRenderer: (cell) => <div>{_.isNotEmpty(cell) ? cell : "None"}</div>,
    },
    {
      header: "Site Installation QA Date",
      dataKey: "site_installation_qa_date",
      cellRenderer: (cell, row) => <div>{row.site_installation_qa ? moment(cell).format("DD/MM/YYYY") : "N/A"}</div>,
      dataFilter: "date",
    },
  ];

  if (updateRole) {
    returnArray.push({
      text: "Edit",
      dataKey: "edit",
      classes: "d-print-none",
      headerClasses: "d-print-none",
      csvExport: false,
      cellRenderer: (cell, row) => <Link to={`/project/${row.project_id}/areas/${row.id}/edit`}>Edit</Link>,
    });
  }

  return returnArray;
};

export default columns;
