import React from "react";

const IFrameWithWaterMark = (props) => {
  const { url,watermark,fontSize, fontWeight, padding, border, borderRadius } = props;

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <iframe src={url} style={{ width: "100%", height: "100%", border: "none" }}></iframe>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          zIndex: 1,
        }}
      >
        {watermark && (
          <div
            style={{
              background: "rgba(255, 255, 255, 0.5)",
              padding: padding,
              border: border,
              borderRadius: borderRadius,
            }}
          >
            <span style={{ fontSize: fontSize, fontWeight: fontWeight }}>{watermark}</span>
          </div>
        )}
      </div>
    </div>
  );
};

IFrameWithWaterMark.defaultProps = {
  fontSize: "24px",
  fontWeight: "bold",
  padding: "24px",
  border: "1px solid #ccc",
  borderRadius: "4px",
};

export default IFrameWithWaterMark;
