import React, { Fragment, useState, useEffect } from "react";
import classnames from "classnames";

import { NavLink, NavItem, Nav, TabContent, TabPane, Row, Col } from "reactstrap";

import Modal from "react-modal";

// import "./modal.css";
import IFrameWithWaterMark from "../../../5_view_helpers/web/IFrameWaterMark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  overlay: { zIndex: 101 },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
  },
};

const DrawingPDFModal = (props) => {
  const { modalOpen, toggle, drawing, label } = props;


  return (
    <Modal isOpen={modalOpen} style={customStyles} onRequestClose={toggle}>
      <DrawingRevisionsTabs toggle={toggle} drawing={drawing} label={label} />
    </Modal>
  );
};

export default DrawingPDFModal;

export const DrawingRevisionsTabs = (props) => {
  const { toggle, drawing, label } = props;
  const [activeTab, setActiveTab] = useState(null);
  const [loadedList, setLoadedList] = useState([]);

  let revisions = _.get(drawing, "revisions_attributes");
  useEffect(() => {
    const lastIndex = revisions?.length - 1;
    setActiveTab(lastIndex);
    setLoadedList([...loadedList, lastIndex]);
  }, [drawing]);


  return (
    <Fragment>
      {label}
      <div className="text-right" >
        <FontAwesomeIcon size="lg" icon={faTimes} style={{ cursor: "pointer" }} onClick={toggle} />
      </div >
      <Nav tabs>
        {_.map(revisions, (revision, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                className={classnames({
                  active: activeTab === i,
                })}
                onClick={() => {
                  setActiveTab(i);
                  setLoadedList([...loadedList, i]);
                }}
              >
                {drawing?.name + " - "}
                {!revision.id && "NOT SAVED - "}
                {revision.mark}
                {revision.latest && " (Latest)"}
                {!loadedList.includes(i) && "*"}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab} style={{ height: "90%" }}>
        {_.map(revisions, (revision, i) => {
          return (
            <TabPane key={i} tabId={i} style={{ height: "100%" }}>
              {revision.drawing_pdf_url ? (
                <Fragment>
                  {(activeTab == i || loadedList.includes(i)) && (
                    <IFrameWithWaterMark
                      url={`${revision.drawing_pdf_url}#view=Fit`}
                      watermark={!revision.latest && "NOT LATEST REVISION!"}
                      fontSize="50px"
                    />
                  )}
                </Fragment>
              ) : (
                <div>Upload Missing PDF here</div>
              )}
            </TabPane>
          );
        })}
      </TabContent>
    </Fragment>
  )
}
