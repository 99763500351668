import React from "react";
import Portal from "./portal";

let routes = [
  {
    path: "/portal",
    component: Portal,
    name: "Portal",
  },

];

export default routes;
