import axios from "axios";

const prepareData = (data) => {return {trigger:data}}

// Plural Actions
export const GET_TRIGGERS = "get_triggers";
export const getTriggers = () => {
	const url = `/triggers.json`
	const request = axios.get(url);
	return {
		type: GET_TRIGGERS,
		payload: request
	};
};


// Singular Actions
export const CREATE_TRIGGER = "create_trigger";
export const createTrigger = (data) => {
	const url = `/triggers.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_TRIGGER,
		payload: request
	};
};

export const GET_TRIGGER = "get_trigger";
export const getTrigger = (id) => {
	const url = `/triggers/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_TRIGGER,
		payload: request
	};
};

export const UPDATE_TRIGGER = "update_trigger";
export const updateTrigger = (id,data) => {
	const url = `/triggers/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_TRIGGER,
		payload: request
	};
};

export const DESTROY_TRIGGER = "destroy_trigger";
export const destroyTrigger = (id) => {
	const url = `/triggers/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_TRIGGER,
		payload: request
	};
};
