import HasRole from "modules/5_view_helpers/HasRole";

const getHireageNav = (custom_forms_menus) => {
  const HireageRoles = HasRole({ category: "Hireages" });

  if (HireageRoles) {
    let hireageMenu = {};
    _.set(hireageMenu, "icon", "fa fa-calendar");
    _.set(hireageMenu, "label", "Hireages");
    _.set(hireageMenu, "content", []);
    let content = _.get(hireageMenu, "content", []);



    const HireExternalRoles = HasRole({
      resource_type: "Hireages::External",
    });
    if (HireExternalRoles) {
      content.push({
        label: "External",
        to: "/hireages/externals",
      });
    }

    const HireCategoryRoles = HasRole({
      resource_type: "Hireages::Category",
    });
    if (HireCategoryRoles) {
      content.push({
        label: "Categories",
        to: "/hireages/categories",
      });
    }

    const thisMenuItems = _.filter(custom_forms_menus, (menu) => menu.parent_type == "hireages");
    _.each(thisMenuItems, (menu) => {
      const menuObject = {
        label: menu.label,
        icon: `fa ${menu.icon}`,
        to: menu.link,
        external: menu.external_link,
      };
      if (menu.has_children) {
        menuObject.content = _.map(menu.children, (children) => {
          return {
            label: children.label,
            icon: `fa ${children.icon}`,
            to: children.link,
            external: children.external_link,
          };
        });
      }
      content.push(menuObject);
    });

    return hireageMenu;
  }
};

export default getHireageNav;
