import axios from "axios";

const prepareData = (data) => {return {custom_forms_menu:data}}

// Plural Actions
export const GET_CUSTOM_FORMS_MENUS = "get_custom_forms_menus";
export const getCustomFormsMenus = () => {
	const url = `/custom_forms/menus.json`
	const request = axios.get(url);
	return {
		type: GET_CUSTOM_FORMS_MENUS,
		payload: request
	};
};


// Singular Actions
export const CREATE_CUSTOM_FORMS_MENU = "create_custom_forms_menu";
export const createCustomFormsMenu = (data) => {
	const url = `/custom_forms/menus.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_CUSTOM_FORMS_MENU,
		payload: request
	};
};

export const GET_CUSTOM_FORMS_MENU = "get_custom_forms_menu";
export const getCustomFormsMenu = (id) => {
	const url = `/custom_forms/menus/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_CUSTOM_FORMS_MENU,
		payload: request
	};
};

export const UPDATE_CUSTOM_FORMS_MENU = "update_custom_forms_menu";
export const updateCustomFormsMenu = (id,data) => {
	const url = `/custom_forms/menus/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_CUSTOM_FORMS_MENU,
		payload: request
	};
};

export const DESTROY_CUSTOM_FORMS_MENU = "destroy_custom_forms_menu";
export const destroyCustomFormsMenu = (id) => {
	const url = `/custom_forms/menus/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_CUSTOM_FORMS_MENU,
		payload: request
	};
};
