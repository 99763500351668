import { 
	GET_CUSTOM_FORMS_MENUS, 
	GET_CUSTOM_FORMS_MENU,
	CREATE_CUSTOM_FORMS_MENU,
	UPDATE_CUSTOM_FORMS_MENU,
	DESTROY_CUSTOM_FORMS_MENU,
} from "./actions"

export function CustomFormsMenusReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_CUSTOM_FORMS_MENUS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_CUSTOM_FORMS_MENU}_FULFILLED`:
		case `${CREATE_CUSTOM_FORMS_MENU}_FULFILLED`:
		case `${UPDATE_CUSTOM_FORMS_MENU}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_CUSTOM_FORMS_MENU}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { CustomFormsMenusReducer } from "modules/custom_forms/menus/reducer"

// custom_forms_menus:CustomFormsMenusReducer,
