import React from 'react';

const CustomFormsMenusIndex = React.lazy(() => import("./index"))
const NewCustomFormsMenus = React.lazy(() => import("./new"))
const EditCustomFormsMenus = React.lazy(() => import("./edit"))

export default[
	{path:'/custom_forms/menus/:id/edit',component: EditCustomFormsMenus,name:'Edit CustomFormsMenu'},
	{path:'/custom_forms/menus/new',component: NewCustomFormsMenus,name:'New CustomFormsMenu'},  
	{path:'/custom_forms/menus',component: CustomFormsMenusIndex,name:'CustomFormsMenus'}, 
]

// import CustomFormsMenusRoutes from "modules/custom_forms/menus/web/routes"
// routes = _.concat(routes,CustomFormsMenusRoutes)
