import React, { Fragment, useState } from "react";
import { Field } from "react-final-form";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import column_types from "./column_types";
import { useFormState } from "react-final-form";
import { InputField, ToggleField } from "modules/4_final_form/mobile";

import { Tabs, Tab, Button, List, BlockTitle, Block } from "framework7-react";
import { getColsAttributes } from "./shared";
import _ from "lodash";

const MobileFormViewer = (props) => {
  const { custom_form, handleSubmit, formLabel, edit, disabled, disabledMessage } = props;
  const latest_revision = _.last(custom_form?.revisions_attributes);
  const pages_attributes = latest_revision?.data?.pages_attributes || [];

  const pages_to_display = _.filter(pages_attributes, (page) => {
    if (edit) {
      return page.show_on_update;
    } else {
      return page.show_on_create;
    }
  });

  const [activeTab, setActiveTab] = useState(0);
  const { values, errors } = useFormState();

  const cols = getColsAttributes(custom_form);
  const commentsReqdOnLastPage = _.some(values?.comments, (value, name) => {
    const findCol = _.find(cols, { name: name });
    return findCol?.show_comments_at_the_end;
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      {custom_form?.show_tabs && (
        <Nav tabs>
          {_.map(pages_to_display, (page, i) => {
            return (
              <NavItem key={i}>
                <NavLink
                  className={classnames({ active: activeTab === i })}
                  onClick={() => {
                    if (custom_form?.enable_tab_nav) {
                      toggle(i);
                    }
                  }}
                  disabled={!custom_form?.enable_tab_nav}
                >
                  {page.name}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      )}
      <Tabs>
        {_.map(pages_to_display, (page, i) => {
          const isFirstPage = i == 0;
          const isLastPage = i == pages_to_display.length - 1 && !commentsReqdOnLastPage;
          const thisPageCols = _.flatMap(page.rows_attributes, "cols_attributes");
          const thisPageInputNames = _.map(thisPageCols, "name");
          const thisPageHasAttachments = _.some(thisPageCols, (col) => {
            return col?.column_type == "attachments";
          });

          const inputsHasErrors = _.some(thisPageInputNames, (inputName) => {
            return _.has(errors?.data, inputName);
          });

          const attachmentsHasErrors = thisPageHasAttachments && _.has(errors, "attachments_attributes");
          const thisPageHasErrors = inputsHasErrors || attachmentsHasErrors;

          const togglePrev = () => {
            toggle(i - 1);
          };
          const toggleNext = () => {
            toggle(i + 1);
          };

          return (
            <Tab tabId={i} key={i} tabActive={i == activeTab}>
              <PageViewer page={page} cols={cols} values={values} isLastPage={isLastPage} />
              <hr />
              {edit && (
                <Fragment>
                  <List>
                    <Field slot="list" component={ToggleField} color="red" name="archived" label="Archived" />
                  </List>
                  <hr />
                </Fragment>
              )}
              <FooterButtons
                togglePrev={togglePrev}
                toggleNext={toggleNext}
                isFirstPage={isFirstPage}
                isLastPage={isLastPage}
                handleSubmit={handleSubmit}
                thisPageHasErrors={thisPageHasErrors}
                custom_form={custom_form}
                formLabel={formLabel}
                disabled={disabled}
                disabledMessage={disabledMessage}
                i={i}
              />
            </Tab>
          );
        })}
        {commentsReqdOnLastPage && (
          <Tab tabId={pages_to_display.length} tabActive={pages_to_display.length == activeTab}>
            <CommentsPage {...props} />
            <hr />
            <FooterButtons
              togglePrev={() => toggle(pages_to_display.length - 1)}
              toggleNext={handleSubmit}
              isFirstPage={false}
              isLastPage={true}
              thisPageHasErrors={false}
              formLabel={formLabel}
              custom_form={custom_form}
              handleSubmit={handleSubmit}
              disabled={disabled}
              disabledMessage={disabledMessage}
            />
          </Tab>
        )}
      </Tabs>
    </Fragment>
  );
};

export default MobileFormViewer;

const FooterButtons = (props) => {
  const { togglePrev, toggleNext, isLastPage, handleSubmit, isFirstPage, thisPageHasErrors, formLabel, custom_form, disabled, disabledMessage, i } =
    props;

  const [submitting, setSubmitting] = useState(false);

  const handleSubmitButton = () => {
    if (disabled) {
      return null;
    } else if (!isLastPage && !thisPageHasErrors) {
      toggleNext();
    } else if (isLastPage && !thisPageHasErrors) {
      setSubmitting(true);
      handleSubmit();
    } else if (thisPageHasErrors) {
      handleSubmit();
    }
  };

  return (
    <div className="grid grid-cols-2 grid-gap">
      {!isFirstPage ? (
        <Button color="red" fill size="sm" onClick={togglePrev}>
          Previous
        </Button>
      ) : (
        <div />
      )}
      <Button
        size="sm"
        color="green"
        fill
        disabled={submitting}
        onClick={handleSubmitButton}
        tooltip={isLastPage && disabled ? disabledMessage : null}
      >
        {isLastPage ? (
          <>
            {formLabel} {custom_form?.name}
          </>
        ) : (
          "Next"
        )}
      </Button>
    </div>
  );
};

const PageViewer = (props) => {
  const {
    page: { rows_attributes },
    isLastPage,
    values,
    cols,
  } = props;

  return (
    <List>
      {_.map(rows_attributes, (row, i) => {
        return <RowViewer slot="list" row={row} values={values} cols={cols} key={i} />;
      })}
    </List>
  );
};

const RowViewer = (props) => {
  const {
    row: { cols_attributes },
    values,
    cols,
  } = props;

  return (
    <Fragment>
      {_.map(cols_attributes, (column, i) => {
        return <ColumnViewer column={column} values={values} cols={cols} key={i} />;
      })}
    </Fragment>
  );
};

const ColumnViewer = (props) => {
  const { column, cols, values } = props;
  const { conditionally_visible, conditional_field, conditional_value } = column;
  const ColumnType = column_types.find((c) => c.value == column.column_type);
  const SelectedFormComponent = ColumnType?.mobileFormComponent || ColumnType?.formComponent;

  let visible = true;
  if (conditionally_visible) {
    const findConditionalColumnType = _.find(cols, { name: conditional_field })?.column_type;
    const findConditionalColumnValue = _.get(values?.data, conditional_field);

    if (findConditionalColumnType == "toggle") {
      if (conditional_value == "true") {
        visible = findConditionalColumnValue;
      } else if (conditional_value == "false") {
        visible = !findConditionalColumnValue;
      }
    } else if (_.isArray(conditional_value)) {
      if (conditional_value?.[0].label == "Present") {
        visible = _.isNotEmpty(findConditionalColumnValue);
      } else {
        const conditional_values = _.map(conditional_value, (v) => v.label.toLowerCase());
        visible = conditional_values.includes(findConditionalColumnValue?.toLowerCase());
      }
    } else {
      visible = conditional_value == findConditionalColumnValue;
    }
  }

  if (SelectedFormComponent && visible) {
    return <SelectedFormComponent {...column} />;
  }
  return null;
};

const CommentsPage = (props) => {
  const { values, errors } = useFormState();

  const { comments } = values;
  const cols = getColsAttributes(props.custom_form);

  return (
    <Fragment>
      {_.map(comments, (value, comment_name) => {
        const findCol = _.find(cols, { name: comment_name });

        if (comments && findCol?.show_comments_at_the_end) {
          return (
            <List key={comment_name}>
              <Field slot="list" type="textarea" component={InputField} label={`${findCol?.label} Comments`} name={`comments.${comment_name}`} />
            </List>
          );
        }
      })}
    </Fragment>
  );
};
