import React, { useEffect } from "react";
import { connect } from "react-redux";

import CustomFormRecordForm from "./form";
import { updateCustomFormRecord, getCustomFormRecord, getCustomFormRecords, destroyCustomFormRecord } from "../actions";

import { Form } from "react-final-form";

// Helpers
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";
import { getCustomForm } from "modules/custom_forms/actions.js";
import { getProjects } from "modules/projects/actions";
import { getEmployees } from "modules/employees/actions";

import arrayMutators from "final-form-arrays";
import { Navbar, Page } from "framework7-react";

function mapStateToProps(state, ownProps) {
  const id = _.get(ownProps, "match.params.id");
  return {
    custom_forms: state.custom_forms,
    custom_form_records: state.custom_form_records,
    projects: state.projects,
    employees: state.employees,
  };
}

const EditCustomFormRecord = (props) => {
  const updateRole = HasRole({ resource_type: "CustomForm::Record", name: ["update", "moderator"] }, true);
  const {
    f7router,
    f7router: { app },
    custom_forms,
    f7route,
    custom_form_records,
    projects,
    employees,
  } = props;
  const { id, custom_form_id } = props.f7route.params;
  const { parent_id } = props.f7route.query;
  const preloader = app.preloader;

  const custom_form_record = custom_form_records?.[id];

  useEffect(() => {
    props.getCustomFormRecord(custom_form_id, id);
    props.getCustomForm(custom_form_id);
    props.getEmployees();
  }, []);

  const custom_form = custom_forms?.[custom_form_id];

  useEffect(() => {
    if (parent_id && custom_form) {
      if (custom_form?.parent_type === "Project") {
        props.getProject(parent_id);
      }
      if (custom_form?.parent_type === "Employee") {
        props.getEmployees(parent_id);
      }
      if (custom_form?.parent_id) {
        props.getCustomFormRecords(custom_form?.parent_id);
      }
    }
  }, [parent_id, custom_form]);

  let parent_object = null;
  if (custom_form?.parent_type === "Project") {
    parent_object = projects?.[parent_id];
  }
  if (custom_form?.parent_type === "Employee") {
    parent_object = employees?.[parent_id];
  }
  if (custom_form?.parent_type === "CustomForm") {
    parent_object = custom_form_records?.[parent_id];
  }

  let returnUrl = `/custom_form/${custom_form?.id}/records`;
  if (parent_id) {
    returnUrl += `?parent_id=${parent_id}`;
  }

  const submitForm = (values) => {
    preloader.show();
    const updateRequest = props.updateCustomFormRecord(custom_form_id, id, values);
    updateRequest.then(successfullySubmitted);
  };

  const successfullySubmitted = () => {
    preloader.hide();
    app.toast
      .create({
        icon: '<i class="material-icons">check</i>',
        text: `Updated ${custom_form?.name}!`,
        position: "center",
        closeTimeout: 2000,
        on: {
          opened: () => {
            f7router.navigate(returnUrl);
          },
        },
      })
      .open();
  };

  if (!updateRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Page>
        <Navbar title={`Updating ${custom_form?.name}`} backLink backLinkUrl={returnUrl} />
        {_.isNotEmpty(custom_form_record) && (
          <Form
            component={CustomFormRecordForm}
            onSubmit={submitForm}
            formLabel="Update"
            initialValues={custom_form_record}
            cancelFunction={() => {
              f7router.navigate(returnUrl);
            }}
            mutators={{
              ...arrayMutators,
            }}
            custom_form={custom_form}
            edit
            {...props}
          />
        )}
      </Page>
    );
  }
};

export default connect(mapStateToProps, {
  getCustomFormRecord,
  updateCustomFormRecord,
  destroyCustomFormRecord,
  getCustomForm,
  getEmployees,
  getProjects,
  getCustomFormRecords,
})(EditCustomFormRecord);
