import React from 'react';

const TriggersIndex = React.lazy(() => import("./index"))
const NewTriggers = React.lazy(() => import("./new"))
const EditTriggers = React.lazy(() => import("./edit"))

export default[
	{path:'/triggers/:id/edit',component: EditTriggers,name:'Edit Trigger'},
	{path:'/triggers/new',component: NewTriggers,name:'New Trigger'},  
	{path:'/triggers',component: TriggersIndex,name:'Triggers'}, 
]

// import TriggersRoutes from "modules/triggers/web/routes"
// routes = _.concat(routes,TriggersRoutes)
