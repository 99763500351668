import React, { Fragment } from "react"
import SlidingPane from "react-sliding-pane"
import NewWindow from 'react-new-window'
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import ProjectQaPaintInspectionsIndex from "modules/project/qa/paint_inspections/web/index.jsx"

const CurrentQASlidingPane = (props) => {
    const [activeTab, setActiveTab] = React.useState('paint_inspections')
    const { isOpen, toggle, project_id, showInNewWindow } = props

    const OpenInType = showInNewWindow ? NewWindow : SlidingPane

    return (
        <OpenInType isOpen={isOpen} onRequestClose={toggle} width="50%">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === 'paint_inspections' })}
                        onClick={() => { toggle('paint_inspections'); }}
                    >
                        Paint Inspections
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="paint_inspections">
                    {activeTab == "paint_inspections" && isOpen && (
                        <ProjectQaPaintInspectionsIndex
                            project_id={project_id}
                            showFilters={false}
                            showNewButton={false}
                            showSelection={true}
                            {...props} />
                    )}
                </TabPane>
            </TabContent>
        </OpenInType>
    )

}

export default CurrentQASlidingPane