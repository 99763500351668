import React from 'react';

const ProjectBoltedConnectionsIndex = React.lazy(() => import("./index"))
const NewProjectBoltedConnections = React.lazy(() => import("./new"))
const EditProjectBoltedConnections = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/qa/bolted_connections/:id/edit',component: EditProjectBoltedConnections,name:'Edit ProjectBoltedConnection'},
	{path:'/project/:project_id/qa/bolted_connections/new',component: NewProjectBoltedConnections,name:'New ProjectBoltedConnection'},  
	{path:'/project/:project_id/qa/bolted_connections',component: ProjectBoltedConnectionsIndex,name:'ProjectBoltedConnections'}, 
]

// import ProjectBoltedConnectionsRoutes from "modules/project/bolted_connections/web/routes"
// routes = _.concat(routes,ProjectBoltedConnectionsRoutes)
