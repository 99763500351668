import {
	GET_PROJECT_BOLTED_CONNECTIONS,
	GET_PROJECT_BOLTED_CONNECTION,
	GET_PROJECT_BOLTED_CONNECTION_BY_GUIDS,
	CREATE_PROJECT_BOLTED_CONNECTION,
	UPDATE_PROJECT_BOLTED_CONNECTION,
	DESTROY_PROJECT_BOLTED_CONNECTION,
	SAVE_MULTIPLE_PROJECT_BOLTED_CONNECTIONS
} from "./actions"

export function ProjectBoltedConnectionsReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_BOLTED_CONNECTION_BY_GUIDS}_FULFILLED`:
		case `${GET_PROJECT_BOLTED_CONNECTIONS}_FULFILLED`: {
			return _.mapKeys(action.payload.data, "id");
		}
		case `${SAVE_MULTIPLE_PROJECT_BOLTED_CONNECTIONS}_FULFILLED`: {
			let newState = _.cloneDeep(state);
			return _.merge(newState, _.mapKeys(action.payload.data, "id"));
		}
		case `${GET_PROJECT_BOLTED_CONNECTION}_FULFILLED`:
		case `${CREATE_PROJECT_BOLTED_CONNECTION}_FULFILLED`:
		case `${UPDATE_PROJECT_BOLTED_CONNECTION}_FULFILLED`: {
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem, "id")
			newState[id] = newItem
			return newState;
		}
		case `${DESTROY_PROJECT_BOLTED_CONNECTION}_FULFILLED`: {
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem, "id")
			newState = _.filter(newState, i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectBoltedConnectionsReducer } from "modules/project/bolted_connections/reducer"

// project_bolted_connections:ProjectBoltedConnectionsReducer,
