import toSentence from "modules/5_view_helpers/to_sentence";
import React from "react";

import { Link } from "react-router-dom";

const columns = (updateRole = false, project_id, showEdit) => {
  let returnArray = [
    {
      header: "Main assembly",
      dataKey: "main_assembly",
      headerStyle: { width: "10rem" },
      sort: true,
      cellRenderer: (cell, row) =>
        row?.main_assembly_changed ? (
          <div>
            {row.old_main_assembly} -> <b style={{ color: "red" }}>{row.main_assembly}</b>
          </div>
        ) : (
          row.main_assembly
        ),
    },
    {
      header: "Connected Assemblies",
      dataKey: "connected_assemblies",
      headerStyle: { width: "10rem" },
      cellRenderer: (cell, row) =>
        row?.connected_assemblies_changed ? (
          <div>
            {row.old_connected_assemblies} -> <b style={{ color: "red" }}>{row.connected_assemblies}</b>
          </div>
        ) : (
          toSentence(row.connected_assemblies)
        ),
    },
    {
      header: "Phase",
      dataKey: "phase",
      headerStyle: { width: "4rem" },
      cellRenderer: (cell, row) =>
        row?.phase_changed ? (
          <div>
            {row.old_phase} -> <b style={{ color: "red" }}>{row.phase}</b>
          </div>
        ) : (
          row.phase
        ),
    },

    {
      header: "Lot",
      dataKey: "lot",
      headerStyle: { width: "4rem" },
      cellRenderer: (cell, row) =>
        row?.lot_changed ? (
          <div>
            {row.old_lot} -> <b style={{ color: "red" }}>{row.lot}</b>
          </div>
        ) : (
          row.lot
        ),
    },

    {
      header: "Qty",
      dataKey: "qty",
      headerStyle: { width: "4rem" },

      cellRenderer: (cell, row) =>
        row?.qty_changed ? (
          <div>
            {row.old_qty} -> <b style={{ color: "red" }}>{row.qty}</b>
          </div>
        ) : (
          row.qty
        ),
    },
    {
      header: "Size",
      dataKey: "size",
      headerStyle: { width: "6rem" },
      cellRenderer: (cell, row) =>
        row?.size_changed ? (
          <div>
            {row.old_size} -> <b style={{ color: "red" }}>{row.size}</b>
          </div>
        ) : (
          row.size
        ),
    },
    {
      header: "Grade",
      dataKey: "grade",
      headerStyle: { width: "4rem" },
      cellRenderer: (cell, row) =>
        row?.grade_changed ? (
          <div>
            {row.old_grade} -> <b style={{ color: "red" }}>{row.grade}</b>
          </div>
        ) : (
          row.grade
        ),
    },
    {
      header: "Joint Type",
      dataKey: "joint_type",
      headerStyle: { width: "4rem" },
      cellRenderer: (cell, row) =>
        row?.joint_type_changed ? (
          <div>
            {row.old_joint_type} -> <b style={{ color: "red" }}>{row.joint_type}</b>
          </div>
        ) : (
          row.joint_type
        ),
    },
    {
      header: "Batch Number",
      dataKey: "batch_number",
    },
    {
      header: "Tensioned By",
      dataKey: "tensioned_by",
    },
    {
      header: "Inspected By",
      dataKey: "inspected_by",
    },
    {
      header: "Inspected On",
      dataKey: "inspected_on",
      cellRenderer: (cell) => fd(cell),
    },
    {
      header: "QA Complete",
      dataKey: "qa_completed",
      cellRenderer: (cell) => (cell ? "Yes" : "No"),
    },
  ];

  if (updateRole && showEdit) {
    returnArray.push({
      header: "Edit",
      dataKey: "edit",
      csvExport: false,
      cellRenderer: (cell, row) => <Link to={`/project/${project_id}/qa/bolted_connections/${row.id}/edit`}>Edit</Link>,
    });
  }

  return returnArray;
};

export default columns;
