import React, { Fragment } from "react";
import { InputField } from "modules/4_final_form/web";
import { FormGroup, Label, Col } from "reactstrap";
import { Field } from "react-final-form";

const TrimbleConnectTab = ({ project }) => {
    return (
        <Fragment>
            <FormGroup row>
                <Label sm={2}>
                    TC Project ID
                </Label>
                <Col sm={2}>
                    <Field name="tc_project_id" component={InputField} parse={(value) => value} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>
                    TC Model ID
                </Label>
                <Col sm={2}>
                    <Field name="tc_model_id" component={InputField} parse={(value) => value} Sw />
                </Col>
            </FormGroup>
        </Fragment>
    )
}

export default TrimbleConnectTab;