import HasRole from "modules/5_view_helpers/HasRole";

import getTrainingNav from "modules/training/web/nav";
import getHireageNav from "modules/hireages/web/nav";
import getDrawingOfficeNav from "modules/drawing_office/web/nav";
import _ from "lodash";

const getNav = (project_id, custom_forms_menus) => {
  let items = [];


  const AddressBookRole = HasRole({ resource_type: "AddressBook::Company" });
  if (AddressBookRole) {
    items.push({
      icon: "fa fa-book",
      label: "Address Book",
      to: `/address_book`,
    });
  }
  const ProjectRoles = HasRole({ resource_type: "Project" });
  if (ProjectRoles) {
    items.push({
      icon: "fa fa-building",
      label: "Projects",
      to: `/projects`,
    });
  }

  const EmployeeRoles = HasRole({ resource_type: "Employee" });
  if (EmployeeRoles) {
    items.push({
      icon: "fa fa-users",
      label: "Employees",
      to: `/employees`,
    });
  }

  const CommunicationsRoles = HasRole({
    resource_type: "Project::Communication",
  });
  if (CommunicationsRoles) {
    items.push({
      icon: "fa fa-comments",
      label: "Communications",
      to: `/project/${project_id}/communications`,
    });
  }
  const DrawingOfficeNav = getDrawingOfficeNav(project_id, custom_forms_menus);
  if (_.isNotEmpty(DrawingOfficeNav)) {
    items.push(DrawingOfficeNav);
  }

  const TrainingNav = getTrainingNav(custom_forms_menus);
  if (_.isNotEmpty(TrainingNav)) {
    items.push(TrainingNav);
  }


  const HireageNav = getHireageNav(custom_forms_menus);
  if (_.isNotEmpty(HireageNav)) {
    items.push(HireageNav);
  }

  const ProductionModule = HasRole({ name: "Production_Module_Access" });
  if (ProductionModule) {
    items.push({
      icon: "fa fa-cogs",
      label: "Production",
      to: `/production`,
      external: true,
    });
  }

  const QAModuleRole = HasRole({ name: "QA_Module_Access" });
  if (QAModuleRole) {
    items.push({
      icon: "fa fa-search",
      label: "Quality Assurance",
      to: `/qa`,
      external: true,
    });
  }


  const StoreModuleRole = HasRole({ name: "Store_Module_Access" });
  if (StoreModuleRole) {
    items.push({
      icon: "fa fa-shopping-cart",
      label: "Store",
      to: `/store`,
    });
  }

  const SettingsRoles = HasRole({ category: "Settings" });
  if (SettingsRoles) {
    let settingsMenu = {};
    _.set(settingsMenu, "icon", "fa fa-cog");
    _.set(settingsMenu, "label", "Settings");

    _.set(settingsMenu, "content", []);
    let content = _.get(settingsMenu, "content", []);
    const AddressBookRoles = HasRole({ resource_type: "AddressBook::Company" }) || HasRole({ resource_type: "AddressBook::Contact" });
    if (AddressBookRoles) {
      content.push({ label: "Address Book", to: "/address_book" });
    }

    const DepartmentRoles = HasRole({ resource_type: "Department" });
    if (DepartmentRoles) {
      content.push({ label: "Departments", to: "/departments" });
    }
    const InternalCompanyRoles = HasRole({ resource_type: "InternalCompany" });
    if (InternalCompanyRoles) {
      content.push({ label: "Internal Companies", to: "/internal_companies" });
    }
    const PositionsRoles = HasRole({ resource_type: "Position" });
    if (PositionsRoles) {
      content.push({ label: "Positions", to: "/positions" });
    }

    const PaintSettingsRoles = HasRole({ resource_type: "Paint::Product" });
    if (PaintSettingsRoles) {
      content.push({ label: "Paint", to: "/paint/settings" });
    }

    if (HasRole("admin")) {
      content.push({ label: "User Groups", to: "/user_groups" });
    }
    if (HasRole("admin")) {
      content.push({ label: "Users", to: "/users" });
    }

    const thisMenuItems = _.filter(custom_forms_menus, (menu) => menu.parent_type == "settings");
    _.each(thisMenuItems, (menu) => {
      const menuObject = {
        label: menu.label,
        icon: `fa ${menu.icon}`,
        to: menu.link,
        external: menu.external_link,
      };
      if (menu.has_children) {
        menuObject.content = _.map(menu.children, (children) => {
          return {
            label: children.label,
            icon: `fa ${children.icon}`,
            to: children.link,
            external: children.external_link,
          };
        });
      }
      content.push(menuObject);
    });

    items.push(settingsMenu);
  }


  return items;
};

export default getNav;

// items.push({
//   icon: "fa fa-briefcase",
//   label: "Health and Safety",
//   content: [
//     {
//       label: "Settings",
//       content: [
//         {
//           label: "SOPs",
//           to: `/hs/sops`,
//         },
//       ],
//     },
//   ],
// });
