import React, { Fragment, Suspense, useEffect } from "react";
import { connect } from "react-redux";

// Layout Related
import { Container } from "reactstrap";
import AppNavbar from "./AppNavbar";
import Sidebar from "./AppSidebar";
import classnames from "classnames";
import { PacmanLoader } from "react-spinners";
import UserNotifications from "./UserNotifications";

import { getCurrentUser, addNotification } from "modules/current_user/actions";
import "./AppLayout.css";

// Router related
import routes from "modules/2_routes/web";
import { Route, Switch } from "react-router-dom";

// Extras
import ErrorsDisplay from "./ErrorsDisplay";
import { Toaster } from "react-hot-toast";
import { Button } from "reactstrap";

import qs from "query-string";

import { getCustomForms } from "modules/custom_forms/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import HasRole from "modules/5_view_helpers/HasRole";
import { getProject } from "modules/projects/actions";
import { toggleFullscreen } from "modules/3_reducers/web/ThemeOptions";

function mapStateToProps(state) {
  return {
    pending: state.pending,
    current_user: state.current_user,
  };
}

const Preloader = () => {
  return (
    <div className="centered">
      <PacmanLoader />
    </div>
  );
};

const AppLayout = (props) => {
  const {
    forceFullscreen,
    fullscreenEnabled,
    location: { pathname, search },
    pending,
    current_user,
    addNotification,
    toggleFullscreen,
  } = props;
  const [showMenu, setShowMenu] = React.useState(false);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [actualMatch, setActualMatch] = React.useState(null);

  const query = qs.parse(search);

  const fullscreen = fullscreenEnabled || forceFullscreen;

  useEffect(() => {
    props.getCurrentUser();
    props.getCustomForms(true);
  }, []);

  useEffect(() => {
    if (fullscreen) {
      const readRole = HasRole({
        resource_type: "Project",
        name: ["read", "moderator"],
      });
      if (readRole) {
        props.getProject(current_user?.current_project_id);
      }
    }
  }, [fullscreen, current_user]);

  let locationType = "main";
  if (_.includes(pathname, "production")) {
    locationType = "production";
  }
  if ((_.includes(pathname, "/qa") || _.includes(pathname, "assemblies")) && query?.return_page != "project") {
    locationType = "qa";
  }
  if (_.includes(pathname, "hs")) {
    locationType = "hs";
  }
  if (_.includes(pathname, "store")) {
    locationType = "store";
  }

  if (_.isEmpty(current_user)) {
    return <div>Loading user...</div>;
  }

  return (
    <Container fluid>
      {!fullscreen && <AppNavbar setShowMenu={setShowMenu} setShowNotifications={setShowNotifications} actualMatch={actualMatch} />}

      <div className="layout">
        {!fullscreen && (
          <div className="sidebar">
            <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} locationType={locationType} />
          </div>
        )}
        <div
          className={classnames({
            "content-fullscreen": fullscreen,
            content: !fullscreen,
          })}
        >
          <ErrorsDisplay />

          {/* Router */}
          <Suspense fallback={<Preloader />}>
            <Switch>
              {_.map(routes, (route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <RouteRenderer
                        setActualMatch={setActualMatch}
                        forceFullscreen={forceFullscreen}
                        fullscreen={fullscreen}
                        pending={pending}
                        route={route}
                        toggleFullscreen={() => {
                          toggleFullscreen(!fullscreen);
                        }}
                        {...props}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </Suspense>
          {/* Router */}
          <Toaster position="top-center" />
          <UserNotifications open={showNotifications} setOpen={setShowNotifications} />
        </div>
        <div id="RightSidebarRoot"></div>
      </div>
    </Container>
  );
};

// export default AppLayout;
export default connect(mapStateToProps, { getCurrentUser, addNotification, getCustomForms, getProject, toggleFullscreen })(AppLayout);

const RouteRenderer = (props) => {
  const {
    match: { path, params },
    match,
    pending,
    route,
    setActualMatch,
    fullscreen,
    forceFullscreen,
    toggleFullscreen,
  } = props;

  useEffect(() => {
    setActualMatch(match);
  }, []);

  // Check if the path needs a project_id
  let needs_project = _.includes(path, ":project_id") && !_.isInteger(parseInt(params?.project_id));
  // special case for project show.
  if (!needs_project) {
    needs_project = _.includes(path, "/projects/:id") && !_.isInteger(parseInt(params?.id));
  }

  return (
    <Fragment>
      {pending != 0 && <Preloader />}
      {!forceFullscreen && (
        <div className="text-right">
          <FontAwesomeIcon className="pr-2" icon={fullscreen ? faCompress : faExpand} onClick={toggleFullscreen} style={{ cursor: "pointer" }} />
        </div>
      )}
      <div className={pending != 0 ? "d-none" : undefined}>{needs_project ? <div>No project selected.</div> : <route.component {...props} />}</div>
    </Fragment>
  );
  yz;
};

const UserNotification = (data) => {
  const { message, notification_type, link, link_text, link_new_tab } = data;

  const duration = 5000;

  if (notification_type == "error" || notification_type == "success") {
    if (link) {
      toastr[notification_type](
        <Fragment>
          {message}
          <a href={link} target={link_new_tab ? "_blank" : null}>
            <Button className="ml-1" color="success">
              {link_text}
            </Button>
          </a>
        </Fragment>,
        { duration: duration }
      );
    } else {
      toastr[notification_type](message, { duration: duration });
    }
  } else {
    toastr(message, { duration: duration });
  }
};
